.send-gift-page .gift-list {
  overflow: auto;
  border: 1px solid var(--border-color-light);
  border-radius: 4px;
  padding: var(--standard-margin);
}
.send-gift-page .gift-list .list-item-wrapper {
  display: flex;
  padding-left: var(--ion-safe-area-left);
  padding-right: var(--ion-safe-area-right);
  margin-bottom: var(--double-margin);
  max-width: 600px;
}
.send-gift-page .gift-list .list-item-wrapper-selected {
  display: flex;
  padding-left: calc(var(--standard-margin) + var(--ion-safe-area-left));
  padding-right: calc(var(--standard-margin) + var(--ion-safe-area-right));
  padding-top: var(--standard-margin);
  padding-bottom: var(--standard-margin);
  margin-bottom: var(--double-margin);
  border: 3px solid var(--ion-color-success);
}
.send-gift-page .gift-list .list-item-col0 {
  flex: 3;
  margin-right: var(--standard-margin);
  position: relative;
  max-width: 80px;
}
.send-gift-page .gift-list .list-item-col1 {
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.send-gift-page .gift-list .list-item-col1-row0 {
  display: flex;
  flex-direction: row;
  /*justify-content: space-between;*/
  align-items: flex-start;
}
.send-gift-page .gift-list .list-item-merchant-name-wrapper {
  flex: 1;
  background-color: var(--text-color-merchant-name-bkg);
  margin-left: var(--standard-margin);
  padding: 4px 0;
  /*border-radius: 20px;*/
}
.send-gift-page .gift-list .list-item-merchant-name {
  color: var(--text-color-light);
  text-align: center;
}
.send-gift-page .gift-list .list-item-date {
  /*font-weight: bold;*/
  margin-top: 4px;
  font-size: .7em;
  color: var(--text-color-light);
}
.send-gift-page .gift-list .list-item-balance {
  font-size: 1.4em;
}
.send-gift-page .gift-list .list-itemAmount {
  font-weight: bold;
  color:var(--text-color-card-balance);
}
.send-gift-page .gift-list .list-itemPennies {
  color:var(--text-color-card-balance-pennies);
  font-size: .7em;
}
