.digitalWalletListItemWrapper {
  display: flex;
  padding-left: calc(var(--standard-margin) + var(--ion-safe-area-left));
  padding-right: calc(var(--standard-margin) + var(--ion-safe-area-right));
  margin-bottom: var(--standard-margin);
  max-width: 600px;
}
.digitalWalletListItemCol0 {
  flex: 3;
  margin-right: var(--standard-margin);
  position: relative;
  max-width: 200px;
}
.digitalWalletListItemCol1 {
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.digitalWalletListItemCol1Row0 {
  display: flex;
  flex-direction: row;
  /*justify-content: space-between;*/
  align-items: flex-start;
}
.digitalWalletListItemmerchantNameWrapper {
  flex: 1;
  background-color: var(--text-color-merchant-name-bkg);
  margin-left: var(--standard-margin);
  padding: 4px 0;
  /*border-radius: 20px;*/
}
.digitalWalletListItemmerchantName {
  color: var(--text-color-light);
  text-align: center;
}
.digitalWalletListItemDate {
  /*font-weight: bold;*/
  margin-top: 4px;
  font-size: .7em;
  color: var(--text-color-light);
}
.digitalWalletListItemBalance {
  font-size: 1.4em;
}
.digitalWalletListItemAmount {
  font-weight: bold;
  color:var(--text-color-card-balance);
}
.digitalWalletListItemPennies {
  color:var(--text-color-card-balance-pennies);
  font-size: .7em;
}
