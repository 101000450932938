.chip-button-row {
  display: flex;
}
.chip-button-row-right-aligned {
  display: flex;
  justify-content: flex-end;
}
.chip-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
  border: 1px solid var(--ion-color-primary);
  border-radius: 1rem;
}
.chip-button.inline {
  display: inline-flex;
}
.chip-button .label {
  font-size: .85rem;
  color: var(--ion-color-primary);
}
.chip-button.chip-button-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.chip-button .chevron {
  width: 1.1rem;
  height: 1.1rem;
  margin-left: 3px;
  color: var(--border-color-light);
}
