.find-user-form {
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
}
.find-user-form .content-wrapper {
  flex: 1;
  display: flex;
}
.find-user-form ion-toggle {
  --handle-height: 15px;
  --handle-width: 15px;
  width: 30px;
  height: 15px;
}
.find-user-form ion-input {
  --padding-start: var(--small-margin);
}
.find-user-form .found-users-wrapper {
  padding: var(--standard-margin);
  border: 1px solid var(--border-color-light);
  border-radius: 4px;
}
.find-user-form .contact-control {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: var(--small-margin);
  color: var(--text-color-light);
}

.find-user-form .below-list-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--standard-margin);
}

.find-user-form .help-button {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: var(--text-color-light);
  font-size: var(--small-text-size);
  cursor: pointer;
}

.find-user-form .results {
  z-index: 10;
  display: flex;
  flex-direction: column;
  /* height: 60vh; */
  overflow: auto;
}
.find-user-form .invite-icon {
  font-size: 24px;
}
.find-user-form .invite-new-text {
  color: var(--ion-color-primary);
}

/* ========================== User List ============================= */
.find-user-form .user-list {
  overflow: auto;
  border: 1px solid var(--border-color-light);
  border-radius: 4px;
  padding: var(--standard-margin);
}
.user-list .group-header {
  color: var(--text-color-light);
  font-size: var(--small-text-size);
  margin-bottom: var(--small-margin);
  letter-spacing: 3px;
}
.find-user-form .user-list .item {
  --padding-start: var(--standard-margin);
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 14px;
  --inner-padding-end: 0;
  --min-height: 0;
  cursor: pointer;
}
.find-user-form .user-list .avatar {
  margin-right: var(--standard-margin);
  width: 2rem;
  height: 2rem;
}
.find-user-form .user-list .select-icon {
  width: 1.6rem;
  height: 1.6rem;
}
