.notifications-item {
  --padding-start: var(--standard-margin);
  --padding-top: 0;
  --padding-end: 0;
  --margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.notifications-item .notification-text {
  flex: 1;
  color: var(--text-color-light);
}
