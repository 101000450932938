.form-group {
  padding: var(--standard-margin);
  padding-top: var(--medium-margin);
  border: 1px solid var(--border-color-light);
  border-radius: 8px;
  position: relative;
}

.form-group .label-wrapper {
  color: var(--text-color-light);
  font-size: var(--small-text-size);
  position: absolute;
  left: var(--standard-margin);
  top: -8px;
  background-color: #ffffff;
  padding: 0 8px;
}

.form-group .label {
  color: var(--text-color-light);
  font-size: var(--small-text-size);
}
