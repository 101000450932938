/* ----------------------- ControlCenter ------------------------ */
.control-center {
  padding: 0;
  --width: 90vw;
  --max-width: 600px;
  --height: 90vh;
  --backdrop-opacity: 10;
  --padding: var(--standard-margin);
  --border-radius: 10px;
}
.control-center .item {
  --padding-start: 6px;
  border-bottom: 1px solid var(--border-color-light);
}
.control-center .footer {
  padding: var(--standard-margin) var(--standard-margin);
  border-top: 1px solid var(--border-color-light);
  text-align: right;
}
