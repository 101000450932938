.contact-page .list-scope-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid var(--border-color-light);
  border-radius: 40px;
  margin-bottom: var(--standard-margin);
  margin-left: var(--standard-margin);
  margin-right: var(--standard-margin);
}
.contact-page .list-scope-header .selected {
  color: var(--ion-color-primary);
}
.contact-page .list-scope-header .unselected {
  color: var(--ion-color-medium);
}
.contact-page .list-scope-header ion-toggle {
  width: 50px;
  height: 26px;
  --background: var(--ion-color-primary);
  --border-color: var(--ion-color-light);
  padding: 5px;
}
