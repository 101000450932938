.purchaseStatusTagsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.purchaseStatusTagsTag {
  color: var(--text-color-light);
  border: 1px solid var(--purchase-status-tag-border-color);
  border-radius: 6px;
  padding: 3px var(--standard-margin);
  margin-left: 3px;
  background-color: white;
  white-space: nowrap;
}
.purchaseStatusTagsTagSmall {
  font-size: .8em;
  color: gray;
  border: 1px solid var(--purchase-status-tag-border-color);
  border-radius: 4px;
  padding: 1px 3px;
  margin-left: 2px;
  white-space: nowrap;
}
.purchaseStatusTagsWrapper .received {
  color: white;
  font-weight: bold;
  /*padding: 2px 4px;*/
  background-color: var(--ion-color-success);
}
