.tagListRow {
  --padding-start: 0;
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --min-height: 0;
  /*border: 1px solid #756ad7;*/
}
.tagListHeaderRow {
  border-bottom: 1px solid var(--border-color-light);
  padding-bottom: 2px;
  margin-bottom: var(--standard-margin);
}
.tagListRowItemContent {
  /*border: 1px solid #1a8ed7;*/
  width: 100%;
  /*padding-top: var(--standard-margin);*/
  padding-bottom: var(--standard-margin);
}
.tagListHeaderRowItemContent {
  /*border: 1px solid #1a8ed7;*/
  width: 100%;
  padding-bottom: 0;
}
.tagListItemRow0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  /*border: 1px solid #1a8ed7;*/
}
.tagListItemRow1 {
  /*border: 1px solid blue;*/
  margin-top: var(--standard-margin);
  padding-bottom: var(--double-margin);
  text-align: right;
  width: 100%;
}
.tagListItemCol0 {
  flex: 2;
  font-size: .8rem;
  margin-right: var(--standard-margin);
  /*border: 1px solid #1a8ed7;*/
}
.tagListItemCol1 {
  flex: 3;
  font-size: .8rem;
  overflow: hidden;
  /*border: 1px solid #6a64d7;*/
}
.tagListItemCol2 {
  flex: 1;
  font-size: .8rem;
  overflow: hidden;
  /*border: 1px solid #6a64d7;*/
}
.tagListItemButton {
  margin-left: 15px;
}
