
.card-content-ios h1 {
  font-size: 1.3rem;
}
.card-content-md h1 {
  font-size: 1.3rem;
}
.card-content-wp h1 {
  font-size: 1.3rem;
}
.card-content-ios h2 {
  font-size: 1.1rem;
  color: var(--ion-color-medium);
}
.card-content-md h2 {
  font-size: 1.1rem;
  color: var(--ion-color-medium);
}
.card-content-wp h2 {
  font-size: 1.1rem;
  color: var(--ion-color-medium);
}
.card-content-ios h3 {
  font-size: .9rem;
  color: var(--ion-color-medium);
}
.card-content-md h3 {
  font-size: .9rem;
  color: var(--ion-color-medium);
}
.card-content-wp h3 {
  font-size: .9rem;
  color: var(--ion-color-medium);
}
.card-content-ios a {
  color: var(--text-color-link);
}
.card-content-md a {
  color: var(--text-color-link);
}
.card-content-wp a {
  color: var(--text-color-link);
}
.card-content-ios p {
  font-size: .9rem;
}
.card-content-md p {
  font-size: .9rem;
}
.card-content-wp p {
  font-size: .9rem;
}
