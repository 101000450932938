/* ----------------------- AppPageFooter ------------------------ */
.app-page-footer {
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 100;
  padding-bottom: var(--ion-safe-area-bottom);
  border-top: 1px solid var(--border-color-light);
}

/* ----------------------- Control Center ------------------------ */
.control-center {
  flex: 1;
  background-color: white;
  position: absolute;
  bottom: calc(var(--ion-safe-area-bottom) + 51px);
  left: 0;
  width: 100%;
  z-index: 100;
}
.control-center .header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--border-color-light);
  padding: 0;
}
.control-center .close-button {
  color: var(--ion-color-medium);
}
.control-center .menu {
  overflow: auto;
  margin-bottom: var(--standard-margin);
}
.control-center .item {
  border-bottom: none;
}
.control-center-backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

/* ----------------------- ActiveUserInfo ------------------------ */
.control-center .active-user-info {
  cursor: pointer;
  --padding-top: var(--double-margin);
  --padding-bottom: var(--standard-margin);
}
.control-center .active-user-info .profile-name-section {
  text-align: left;
  text-transform: none;
}
.control-center .active-user-info .handle {
  font-size: var(--small-text-size);
  margin-top: 6px;
  color: var(--text-color-light)
}
.control-center .active-user-info .large-handle {
  text-transform: none;
}
.control-center .active-user-info .avatar {
  margin-right: var(--standard-margin);
  width: 50px;
  height: 50px;
}

/* ----------------------- AppTabs ------------------------ */
.app-page-footer .app-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
}
.app-page-footer .app-tabs .app-tab {
  flex: 1;
  display: flex;
  justify-content: center;
}
.app-page-footer .app-tabs .button {
  margin: 0;
  height: auto;
  --padding-top: 6px;
  --padding-bottom: 6px;
  --padding-start: 0;
  --padding-end: 0;
}
.app-page-footer .app-tabs .app-tab .button-content {
  /*padding: 2px 20px;*/
}
.app-page-footer .app-tabs .app-tab .icon-with-badge {
  position: relative;
  padding-left: 10px;
  padding-right: 20px;
}
.app-page-footer .app-tabs .app-tab .icon {
  width: 26px;
  height: 26px;
  color: var(--ion-color-medium);
}
.app-page-footer .app-tabs .app-tab-selected .icon {
  color: var(--ion-color-primary);
}
.app-page-footer .app-tabs .app-tab .label {
  font-size: var(--xsmall-text-size);
  color: var(--text-color-light);
}
.app-page-footer .app-tabs .app-tab-selected .label {
  color: var(--ion-color-primary);
}
.app-page-footer .app-tabs .app-tab .badge {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2000;
}
.app-page-footer .app-tabs .open-control-center-tab {
  /* justify-content: flex-end;
  width: 60px; */
}
