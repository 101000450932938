.faq-item {
  margin-bottom: var(--double-margin);
}
.faq-item .question-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.faq-item .question {
  font-size: 1.2rem;
  color: var(--ion-color-medium);
}
