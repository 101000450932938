.purchaseListItemWrapper {
  display: flex;
  /*padding: 8px;*/
  padding: 0 var(--standard-margin);
  margin-bottom: var(--standard-margin);
  max-width: 600px;
}
.purchaseListItemCol0 {
  flex: 3;
  margin-right: var(--standard-margin);
  position: relative;
  max-width: 200px;
}
.purchaseListItemCol1 {
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.purchaseListItemCol1Row0 {
  display: flex;
  flex-direction: row;
  /*justify-content: space-between;*/
  align-items: flex-start;
}
.purchaseListItemMerchantNameWrapper {
  flex: 1;
  background-color: var(--text-color-merchant-name-bkg);
  margin-left: var(--standard-margin);
  padding: 4px 0;
  /*border-radius: 20px;*/
}
.purchaseListItemMerchantName {
  color: var(--text-color-light);
  text-align: center;
}
.purchaseListItemDate {
  /*font-weight: bold;*/
  margin-top: 4px;
  font-size: .7em;
  color: var(--text-color-light);
}
.purchaseListItemBalance {
  font-size: 1.4em;
}
.purchaseListItemAmount {
  font-weight: bold;
  color:var(--text-color-card-balance);
}
.purchaseListItemPennies {
  color:var(--text-color-card-balance-pennies);
  font-size: .7em;
}

