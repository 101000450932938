.chat-attachment.gift-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*border: 1px solid var(--border-color-light);*/
  /*padding: var(--small-margin) var(--standard-margin);*/
  /*border-radius: 5px;*/
  cursor: pointer;
}
.chat-attachment.gift-card .image {
  width: 160px;
  padding: 5px;
}
