.order-page .section-icon {
  width: 22px;
  height: 22px;
  margin-right: 6px;
  color: var(--ion-color-medium);
}
.order-page .section-header {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: var(--ion-color-medium);
}
.order-page .section-content {
  margin-left: 28px;
  margin-bottom: var(--double-margin);
}
