.chat-image-modal .modal-wrapper {
  border-radius: 12px;
  width: 90vw;
  height: 90vh;
  padding: var(--standard-margin);
}
.chat-image-modal .modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.chat-image-modal .header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.chat-image-modal .grid {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: var(--standard-margin);
  padding-top: 0;
}
