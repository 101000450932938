.contact-all-list-item {
  --padding-start: var(--standard-margin);
  --padding-end: 0;
  --inner-padding-end: 0;
}
.contact-all-list-item .item-content {
  flex: 1;
  display: flex;
  overflow: hidden;
  padding: var(--small-margin) 0;
}
.contact-all-list-item .content-col {
  flex-grow: 1;
  min-width: 0;
  padding-left: 5px;
  padding-top: var(--standard-margin);
  padding-bottom: var(--standard-margin);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-all-list-item .control-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact-all-list-item .next-button {
  margin-top: auto;
  margin-bottom: auto;
}
.contact-all-list-item .birthday-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
  font-size: var(--small-text-size);
  color: var(--text-color-light);
  min-width: 0;
}
.contact-all-list-item .full-name {
  font-size: 1.2rem;
  letter-spacing: 2px;
}
.contact-all-list-item .username {
  color: var(--text-color-light);
}
.contact-all-list-item .chat-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
}
.contact-all-list-item .birthday-icon-fontsize-small {
  font-size: var(--small-text-size);
  margin-right: 3px;
  margin-bottom: 2px;
}
.empty-contact-list .contacts-action {
  display: flex;
  flex-direction: row;
  max-width: 500px;
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 8px;
  padding: var(--standard-margin);
  margin: var(--standard-margin);
}

/* ------------------------------------------ Contact List Item Sliding ------------------------------------------- */
.contact-all-list-item-sliding .action-icon {
  margin-bottom: var(--standard-margin);
  width: 26px;
  height: 26px;
}
