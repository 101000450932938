.gift-card-header {
  padding-top: var(--standard-margin);
  display: flex;
  align-items: center;
  flex-direction: column;
  /*background-color: #fbf7f7;*/
}
.giftCardHeaderCardWrapper {
  max-width: 400px;
  margin-bottom: var(--standard-margin);
}
.gift-card-header .action-and-tag-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: calc(var(--standard-margin) + var(--ion-safe-area-right));
  margin-top: var(--standard-margin);
  margin-bottom: var(--standard-margin);
  width: 100%;
}
.gift-card-header .action-and-tag-section .action-pane {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.gift-card-header .action-and-tag-section .tags-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.giftCardHeaderDeletePurchaseIcon {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--text-color-light);
  margin-left: var(--standard-margin);
}
.balance-section-large {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--double-margin);
  margin-bottom: var(--double-margin);
}
.balance-section-medium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--small-margin);
  margin-bottom: var(--small-margin);
}
.balance-section-small {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--small-margin);
  margin-bottom: var(--small-margin);
}
.balance-section-large .balance {
  color: var(--text-color-card-balance);
  font-weight: bold;
  font-size: 3.5rem;
}
.balance-section-medium .balance {
  color: var(--text-color-card-balance);
  font-weight: bold;
  font-size: 2rem;
}
.balance-section-small .balance {
  color: var(--text-color-card-balance);
  font-weight: bold;
  font-size: 1.4rem;
}
.balance-section-large .currency {
  font-size: 1.5rem;
  color: var(--text-color-light);
  margin-right: 4px;
}
.balance-section-medium .currency {
  font-size: 1rem;
  color: var(--text-color-light);
  margin-right: 4px;
}
.balance-section-small .currency {
  font-size: .8rem;
  color: var(--text-color-light);
  margin-right: 4px;
}
.purchasePageCardCodeSection {
  /*margin-top: 20px;*/
  /*margin-bottom: 20px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
}
