.processing-overlay .backdrop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1000;
}
.processing-overlay .content-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -1000;
}
.processing-overlay .content {
  text-align: center;
  overflow: hidden;
  background-color: #ffffff;
  padding: var(--double-margin);
  border-radius: 10px;
}
.processing-overlay .spinner {
  color: var(--text-color-xlight);
}
.processing-overlay .text {
  color: var(--text-color-light);
  text-align: center;
}

/* ====== */
.processing-popover div.popover-content {
  /*top: 50% !important;*/
  /*left: 50% !important;*/
  /*transform: translate(-50%, -50%) !important;*/
  padding: var(--double-margin);
  min-width: 250px;
  min-height: 250px;
  display: flex;
}
.processing-popover div.popover-content .popover-viewport {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.processing-popover div.popover-content .caption {
  text-align: center;
  margin-bottom: var(--standard-margin);
  font-size: 1.3rem;
}
.processing-popover div.popover-content .spinner {
  /*width: 8rem;*/
  /*height: 4rem;*/
  color: var(--text-color-light);
}
