.productOptionListRow {
  --padding-start: 0;
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --min-height: 0;
  /*border: 1px solid #756ad7;*/
}
.productOptionListHeaderRow {
  border-bottom: 1px solid var(--border-color-light);
  padding-bottom: 2px;
  margin-bottom: var(--standard-margin);
}
.productOptionListRowItemContent {
  /*border: 1px solid #1a8ed7;*/
  width: 100%;
  padding-top: var(--standard-margin);
  padding-bottom: var(--standard-margin);
}
.productOptionListHeaderRowItemContent {
  /*border: 1px solid #1a8ed7;*/
  width: 100%;
  padding-bottom: 0;
}
.productOptionListRow0 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /*border: 1px solid #1a8ed7;*/
}
.productOptionListRowCol {
  flex: 1;
  padding-right: var(--standard-margin);
  font-size: .8rem;
  text-align: center;
}
.productOptionListRowCol0 {
}
.productOptionListRow0Col1 {
}
.productOptionListRow0Col2 {
}
.productOptionListRow0Col3 {
}
.productOptionListRow0Col4 {
}
.productOptionListRow0Col5 {
}
.productOptionListRow0Col6 {
}
.productOptionListRow0Col7 {
  padding-right: 0;
  /*border: 1px solid #1a8ed7;*/
}
.productOptionListRowCol1 {
  flex: 3;
  padding-top: var(--standard-margin);
  height: 100%;
}
.productOptionListRowCol1Row0 {

}
.productOptionListRowProductOptionName {
  color: #676e81;
  font-size: 1.2em;
  /*border: 1px solid #479156;*/
}
.productOptionListRowProductOptionUrl {
  margin-top: 5px;
  font-size: .8em;
  color: #a0abc1;
}
.productOptionListRowLinkIcon {
  width: var(--standard-margin);
  margin-right: 5px;
}
.productOptionListRowImg {
  width: 120px;
}
.productOptionListRowButton {
  margin-left: 15px;
}
.productOptionListRowVisitIcon {
  margin-left: var(--standard-margin);
}