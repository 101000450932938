.shopping-cart {
  margin-bottom: var(--double-margin);
}
.shopping-cart .cart-row {
  display: flex;
  border-bottom: 1px solid var(--border-color-light);
  padding: var(--small-margin) 0;
}
.shopping-cart .header-row {
  padding: 0;
}
.shopping-cart .cart-col {
  padding: 2px;
}
.shopping-cart .header-col {
  text-align: center;
  color: #808080;
  background-color: #f9f9f9;
  font-size: .8rem;
}
.shopping-cart .product-title {
  margin-bottom: var(--standard-margin);
}
.shopping-cart .cart-col0 {
  flex: 1;
}
.shopping-cart .cart-col1 {
  padding-left: var(--small-margin);
  flex: 4;
}
.shopping-cart .cart-col1 .cart-col1-row0 {
  margin-bottom: var(--small-margin);
}
.shopping-cart .cart-col2 {
  flex: 1.4;
  text-align: center;
  padding-right: var(--standard-margin);
}
.shopping-cart .product-count-out-of-stock {
  color: var(--ion-color-warning);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.shopping-cart .cart-col3 {
  flex: 1.5;
  text-align: center;
}
.shopping-cart .header-col.cart-col3 {
  border-top-right-radius: 14px;
}
.shopping-cart .header-col.cart-col0 {
  border-top-left-radius: 14px;
}
.shopping-cart .total-amount {
  font-weight: bold;
}
.shopping-cart .item-reward {
  font-size: 0.8rem;
  padding: 2px;
  width: fit-content;
  color: var(--text-color-light);
}
.shopping-cart .item-reward.with-border {
  border: solid 1px var(--border-color-light);
  border-radius: 6px;
  width: fit-content;
  background-color: #fbffef;
  color: #728e43;
  /*color: var(--ion-text-color);*/
}
.shopping-cart .item-reward .icon {
  color: #cacd27;
}
.shopping-cart .item-reward .label {
  /*color: var(--text-color-light);*/
}
.shopping-cart .add-item-row {
  margin-top: var(--small-margin);
  text-align: right;
}
.shopping-cart .out-of-stock-row-note {
  color: var(--ion-color-warning);
  font-size: var(--small-text-size);
  margin-top: var(--small-margin);
}
