.new-invitation-page .found-user {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--border-color-light);
  border-radius: 6px;
  margin-bottom: var(--double-margin);
  background-color: var(--border-color-xlight);
}
.new-invitation-page .found-user-col0 {
  padding: var(--standard-margin);
}
.new-invitation-page .found-user-col1 {
  padding-top: var(--standard-margin);
  padding-right: var(--standard-margin);
  padding-bottom: var(--standard-margin);
}
.inviteTip {
  margin: auto;
  border: 1px solid var(--border-color-light);
  max-width: 325px;
  padding-top: var(--standard-margin);
  padding-right: var(--small-margin);
  padding-bottom: var(--standard-margin);
  padding-left: var(--small-margin);
  border-radius: 6px;
  color: var(--text-color-medium-light);
  background-color: var(--border-color-xlight);
}
