.edit-avatar-modal .modal-wrapper {
  border-radius: 12px;
  max-height: 90vh;
  max-width: 90vw;
}
.edit-avatar-modal .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: var(--standard-margin);
}
.edit-avatar-modal .info-icon {
  padding-right: var(--small-margin);
  font-size: 1.2rem;
  color: var(--text-color-light);
}
.edit-avatar-modal .form-button-wrapper {
  margin: var(--standard-margin) 0;
  text-align: center;
}
.edit-avatar-modal .modal-wrapper #image-upload {
  position: absolute;
  opacity: 0;
}
