.checkout-product-option-page .token-balance-level {
  border-bottom: 1px dashed var(--text-color-xlight);
  text-align: right;
  font-size: .8rem;
  color: var(--text-color-xlight);
  margin-bottom: var(--small-margin);
}
.checkout-product-option-page .token-balance-level-icon {
  margin-right: var(--small-margin);
}
