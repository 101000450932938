.latest-chat-message-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.latest-chat-message-info .row0 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.latest-chat-message-info .row0 .icon {
  margin-right: 4px;
  color: var(--text-color-light);
  flex-shrink: 0;
}
.latest-chat-message-info .row0 .text {
  font-size: .9rem;
  font-style: italic;
  min-width: 0;
  color: #6a99c1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.latest-chat-message-info .sent-at {
  color: var(--text-color-light);
  font-size: x-small;
}
