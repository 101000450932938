.user-account-page .orders-tab {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: var(--standard-margin);
  padding-top: var(--double-margin);
}
.user-account-page .orders-tab .order-list {
  flex: 1;
  overflow: auto;
  border: 1px solid var(--border-color-light);
  border-radius: 4px;
  padding: var(--standard-margin);
  margin-top: var(--standard-margin);
}
.user-account-page .orders-tab .order-list .item {
  --padding-start: 0;
  --padding-top: 10px;
  --padding-end: 0;
  --padding-bottom: 10px;
  --inner-padding-end: 0;
  --min-height: 0;
  border-bottom: 1px solid var(--ion-color-light);
  cursor: pointer;
}
