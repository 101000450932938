.gift-transfer-message .avatar {
  width: 36px;
  height: 36px;
  margin-right: var(--standard-margin);
}
.gift-transfer-message .message {
  max-width: fit-content;
  font-style: italic;
  overflow: auto;
  padding-left: calc(var(--standard-margin) + var(--ion-safe-area-left));
  padding-top: var(--standard-margin);
  padding-right: calc(var(--standard-margin) + var(--ion-safe-area-right));
  padding-bottom: var(--standard-margin);
  border: 1px solid #e8e8e8;
  -webkit-box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
  -moz-box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
  box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
  border-radius: 8px;
  border-top-left-radius: 0;
}
