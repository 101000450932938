.chat .tokens-transfer-form {
  margin: var(--standard-margin);
  border: 1px solid lightgray;
  border-radius: 10px;
}
.chat .tokens-transfer-form .caption-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
