.purchasePagePreCaption {
  color: gray;
  /*font-size: 20px;*/
}
.purchasePageCaption {
  margin-top: 4px;
  font-size: 20px;
  margin-bottom: 20px;
}
.purchasePageZoomedBarcodeWrapper {
  height: 100%;
}
.purchasePageDetailsWrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
}
.purchasePageAmountWrapper {
}
.purchasePageAmount {
  color: var(--text-color-card-balance);
  font-weight: bold;
  font-size: 3.5rem;
}
.purchasePageAmountPennies {
  color: var(--text-color-card-balance-pennies);
  font-size: 1.5rem;
}
.purchasePageCurrency {
  font-size: 1.5rem;
  color: var(--text-color-light);
  margin-right: 4px;
}
.purchasePageCardCodeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.purchasePageCode {
  /*font-family: "Lucida Console", "Menlo", "Monaco", "Courier", monospace;*/
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: var(--standard-margin);
}
.purchasePageCodeHasBarcode {
  font-size: 1.3rem;
  margin-top: var(--standard-margin);
}
.purchasePagePin {
  /*font-family: "Lucida Console", "Menlo", "Monaco", "Courier", monospace;*/
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 3px;
}
.purchasePagePinSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.purchasePageBalanceFormInputModeChoice {
  --padding-start: 0;
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --min-height: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
}
.purchasePageBalanceFormInputModeRadio {
  margin-right: var(--standard-margin);
}
.purchasePageBalanceInput {
  text-align: center;
}
.purchasePageHeaderSlide {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
}
.purchase-page .balance-chart {
  height: 22vh;
  max-width: 90vw;
  position: relative;
  background-color: #ffffff;
  border: 1px solid var(--border-color-light);
}
.purchase-page .gift-fab-button {
  position: absolute;
  bottom:80px;
  right: 20px;
  z-index: 1;
  background-color: #ffff;
}
.purchase-page .gift-fab-button .gift-button-icon {
  color: #b79710;
}
