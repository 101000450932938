.tokens-page .tokens-wallet-chart {
  -webkit-box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
  -moz-box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
  box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
  border: 1px solid var(--border-color-light);
  width: 90%;
  margin-top: var(--double-margin);
  margin-left: var(--standard-margin);
  margin-right: var(--standard-margin);
  margin-bottom: var(--double-margin);
  text-align: center;
}
.tokens-page .tokens-wallet-chart .timeline-buttons {
  margin-top: var(--standard-margin);
  text-align: right;
}
.tokens-page .tokens-wallet-chart .graph-container {
  position: relative;
  height: 25vh;
  width: 90%;
  padding: var(--standard-margin) 0;
}
