.user-info-card {
  border-radius: 8px;
  margin: 0;
}
.user-info-card .header-container {
  display: flex;
}
.user-info-card .container {
  min-width: 10%;
}
.user-info-card .info-container {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.user-info-card .button-container {
  align-items: center;
  display: flex;
}
.user-info-card ion-card-content {
  padding-bottom: var(--small-margin);
}
.user-info-card .avatar {
  margin: var(--standard-margin);
  width: 3rem;
  height: 3rem;
}
.user-info-card .birthday-section {
  display: flex;
  align-items: center;
  padding: var(--small-margin);
}
.user-info-card .birthday-icon-fontsize-small {
  font-size: .9rem !important;
  margin-right: 3px;
  margin-bottom: 2px;
}
.user-info-card .username {
  text-transform: none;
  color: var(--ion-color-medium);
}
.user-info-card .message-section {
  padding: var(--small-margin) !important;
  display: flex;
}
.user-info-card .contact-info-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: var(--standard-margin) !important;
  padding-right: var(--standard-margin) !important;
  margin-bottom: var(--small-margin);
}
.user-info-card .contact-info-line .contact-info-line-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-info-card .open-button-row {
  text-align: right;
  padding-right: var(--standard-margin);
  padding-bottom: var(--standard-margin);
}
.user-info-card .close-button {
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: var(--ion-color-medium);
  margin-bottom: var(--standard-margin);
}
.user-info-card .card-header {
  align-items: center;
  justify-content: space-between;
  background-color: #fff6d7;
  color: #fdd53e;
  padding: 8px;
}
