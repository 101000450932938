.wallet-header {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-bottom: 1px solid var(--border-color-light);
}
.wallet-header .content-row0 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: var(--standard-margin);
  padding-bottom: var(--small-margin);
}
.wallet-header .content-col0 {
  flex-direction: column;
  justify-content: center;
  padding-left: var(--double-margin);
}
.content-col0 .mit-button-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: var(--standard-margin);
}
.mit-button-wrapper .token-image {
  width: 50px;
  height: 50px;
}
.mit-button-wrapper .add-mit-button {
  bottom: 25px;
  left: 48px;
  z-index: 1;
  max-height: 18px;
  min-height: 18px;
  min-width: 18px;
  max-width: 18px;
}
.wallet-header .content-col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wallet-header .content-col1 .currency {
  font-size: var(--small-text-size);
  color: var(--text-color-light);
  margin-bottom: var(--small-margin);
  align-self: flex-end;
}
.wallet-header .content-col1 .balance {
  font-size: 2rem;
  font-weight: bold;
  color: var(--ion-color-primary);
}
.wallet-header .content-col1 .balance-wrapper {
  position: relative;
  margin: 0 auto;
}
.wallet-header .content-col1 .info-button {
  position: absolute;
  z-index: 1;
  right: -30px;
  top: -10px;
  width: fit-content;
  font-size: .8rem;
  color: var(--ion-color-primary);
}
.wallet-header .content-col2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
