.purchase-history .header-row {
  border-bottom: 1px solid var(--border-color-light);
}
.purchase-history .col {
  flex: 1;
  font-size: .8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.purchase-history .first-row {
  font-weight: bold;
}
.purchase-history .odd-row {
  background-color: var(--ion-color-light);
}

/* ------------------------------------------------------------------------------------------------------------------ */
/*
@media screen and (max-width: 600px) {
  .historyEventListItemCol4 {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .historyEventListItemCol {
    font-size: .8rem;
  }
  .historyEventListItemCol1 {
    display: none;
  }
}
*/
