.appVersionInfoSection {
  margin: var(--double-margin) 0;
}
.appVersionInfoSectionRow0 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.loadLatestAppVersionIcon {
  margin-left: var(--standard-margin);
  width: 1.2rem;
  height: 1.2rem;
}