.shared-with-contacts-notice {
  color: var(--text-color-light);
  margin-top: var(--small-margin);
  margin-bottom: var(--standard-margin);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.7rem;
}
.shared-with-contacts-notice .icon {
  flex-shrink: 0;
  width: 0.7rem;
  height: 0.7rem;
  margin-right: var(--small-margin);
}
