.o-auth-buttons .inner {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.o-auth-buttons .button {
  margin-right: var(--standard-margin);
}

@media screen and (max-width: 500px) {
  .o-auth-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .o-auth-buttons .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .o-auth-buttons .button {
    margin-right: 0;
    width: 100%;
    min-width: 40px;
  }
  .o-auth-buttons .button .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
