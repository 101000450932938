.new-uploaded-purchase-page .gift-card-image {
  width: 75% !important;
}

@media only screen and (min-width: 500px) {
  .new-uploaded-purchase-page .gift-card-image {
    width: 50% !important;
  }
}

@media only screen and (min-width: 900px) {
  .new-uploaded-purchase-page .gift-card-image {
    width: 30% !important;
  }
}
