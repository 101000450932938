.tags-table .tags-table-cell {
  border: 1px solid black;
}
.tags-page .header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tags-page .add-tag-icon {
  width: 2.2rem;
  height: 2.2rem;
  padding: 0 var(--double-margin);
  color: var(--text-color-light);
}
