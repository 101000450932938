/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: #fff;
  --ion-toolbar-color-activated: #fff;

  --ion-text-color: #575757;

  /** primary **/
  --ion-color-primary: #4354ae;
  --ion-color-primary-rgb: 67, 84, 174;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3b4b99;
  --ion-color-primary-tint: #5368d1;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning-original: #ffce00;
  --ion-color-warning-original-rgb: 255, 206, 0;
  --ion-color-warning-original-contrast: #ffffff;
  --ion-color-warning-original-contrast-rgb: 255, 255, 255;
  --ion-color-warning-original-shade: #e0b500;
  --ion-color-warning-original-tint: #ffd31a;

  --ion-color-warning: #ef8f01;
  --ion-color-warning-rgb: 239,143,1;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #d27e01;
  --ion-color-warning-tint: #f19a1a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** handle-glyph **/
  --ion-color-handle-glyph: #679713;
  --ion-color-handle-glyph-rgb: 172,133,9;
  --ion-color-handle-glyph-contrast: #000000;
  --ion-color-handle-glyph-contrast-rgb: 0,0,0;
  --ion-color-handle-glyph-shade: #5b8511;
  --ion-color-handle-glyph-tint: #76a12b;

  /** welcome **/
  --ion-color-welcome-header: #679713;
  --ion-color-welcome-header-rgb: 172,133,9;
  --ion-color-welcome-header-contrast: #000000;
  --ion-color-welcome-header-contrast-rgb: 0,0,0;
  --ion-color-welcome-header-shade: #5b8511;
  --ion-color-welcome-header-tint: #76a12b;

  /** text message **/
  --ion-color-text-message: #f7f9ff;
  --ion-color-text-message-rgb: 247,249,255;
  --ion-color-text-message-contrast: #59638a;
  --ion-color-text-message-contrast-rgb: 0,0,0;
  --ion-color-text-message-shade: #d9dbe0;
  --ion-color-text-message-tint: #f8faff;

  --ion-headings-font-weight: 300;

  /* App specific: */
  --text-color-light: #8c8b8b;
  --text-color-xlight: #c4c4c4;
  --text-color-medium-light: #636363;
  --text-color-link: #18558a;
  --text-color-card-balance: #484879;
  --text-color-card-balance-pennies: #7b798e;
  --text-color-merchant-name-bkg: #f9f8f8;
  --purchase-status-tag-border-color: #d8d8d8;
  --border-color-light: #d8d8d8;
  --border-color-input: rgba(0, 0, 0, 0.23);
  --border-color-xlight: #f1f0f0;
  --border-color-form-error: #e05c4c;
  --small-margin: 4px;
  --standard-margin: 10px;
  --medium-margin: 15px;
  --app-menu-margin: 12px;
  --double-margin: 20px;
  --triple-margin: 30px;
  --quadruple-margin: 40px;
  --top-nav-bar-text-color-inactive: var(--text-color-light);
  --top-nav-bar-text-color-active: #3b5998;
  --top-nav-bar-bottom-border-color-active: #3b5998;
  --small-button-height: 26px;
  --small-text-size: 0.8rem;
  --xsmall-text-size: 0.6rem;
  --xxsmall-text-size: 0.4rem;
  --large-text-size: 1.2rem;
  --xlarge-text-size: 1.6rem;
  --xxlarge-text-size: 2.4rem;

  --dashboard-tile-mimble-wallet: #3b5998;
  --dashboard-tile-mimble-wallet-caption: #4683cd;
  --dashboard-tile-gift-cards: #b16be3;
  --dashboard-tile-gift-cards-caption: #bc8eff;
  --dashboard-tile-incoming-transfers: #86B45F;
  --dashboard-tile-incoming-transfers-caption: #9dd073;
  --dashboard-tile-outgoing-transfers: #b4aa22;
  --dashboard-tile-outgoing-transfers-caption: #d5cb22;
}

.inputWithNoPadding {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-left: 0;
  --padding-right: 0;
  --padding-start: 0;
  --padding-end: 0;
}

/* Additional Ionic Colors
// --------------------------------------------------
// In order to add colors to be used with Ionic components,
// the color should be added as a class with the convention `.ion-color-{COLOR}`
// where `{COLOR}` is the color to be used on the Ionic component
// and each variant is defined for the color. For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
*/

.ion-color-favorite {
  --ion-color-base: #69bb7b;
  --ion-color-base-rgb: 105, 187, 123;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #5ca56c;
  --ion-color-tint: #78c288;
}

.ion-color-twitter {
  --ion-color-base: #1da1f4;
  --ion-color-base-rgb: 29, 161, 244;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1a8ed7;
  --ion-color-tint: #34aaf5;
}

.ion-color-google {
  --ion-color-base: #dc4a38;
  --ion-color-base-rgb: 220, 74, 56;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #c24131;
  --ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
  --ion-color-base: #23b6ea;
  --ion-color-base-rgb: 35, 182, 234;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1fa0ce;
  --ion-color-tint: #39bdec;
}

.ion-color-facebook {
  --ion-color-base: #3b5998;
  --ion-color-base-rgb: 59, 89, 152;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #344e86;
  --ion-color-tint: #4f6aa2;
}

.ion-color-balance {
  --ion-color-base: #7b7bab;
  --ion-color-base-rgb: 123, 123, 171;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #4d4d91;
  --ion-color-tint: #a2a2d3;
}

.ion-color-token-amount {
  --ion-color-token-amount: #fbffef;
  --ion-color-token-amount-rgb: 251,255,239;
  --ion-color-token-amount-contrast: #728e43;
  --ion-color-token-amount-contrast-rgb: 114,142,67;
  --ion-color-token-amount-shade: #647d3b;
  --ion-color-token-amount-tint: #809956;
}

.ion-color-text-message {
  --ion-color-base: var(--ion-color-text-message);
  --ion-color-base-rgb: var(--ion-color-text-message-rgb);
  --ion-color-contrast: var(--ion-color-text-message-contrast);
  --ion-color-contrast-rgb: var(--ion-color-text-message-contrast-rgb);
  --ion-color-shade: var(--ion-color-text-message-shade);
  --ion-color-tint: var(--ion-color-text-message-tint);
}

/* does not seem to work: */
ion-textarea {
  --padding-top: 0;
  --padding-bottom: 0;
}
.sc-ion-textarea-md-h {
  margin-top: 0;
}
ion-input {
  --padding-top: 4px;
  --padding-bottom: 4px;
}

body {
  /*background-color: #f5f5f5;*/
}
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.3rem;
  color: var(--ion-color-medium);
}
h3 {
  font-size: 1.2rem;
  color: var(--ion-color-medium);
}
a {
  color: var(--text-color-link);
}

/* ------------------------------------------- Material UI -------------------------------------------- */
.MuiInputBase-root .MuiOutlinedInput-input {
  padding: 15px 12px;
  color: var(--ion-text-color);
}
.MuiOutlinedInput-adornedEnd {
  padding-right: 0;
}
.MuiStepper-root {
  padding: 0 !important;
}
.MuiSvgIcon-fontSizeSmall {
  font-size: 1rem !important;
}
.x-grid-data-view {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  /*border: 4px solid deepskyblue;*/
}
.x-grid-wrapper {
  flex-grow: 1;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  /*border: 4px solid yellowgreen;*/
}

/* ---------------------------------------------------------------------------------------------------- */
.g-ion-card-content {
  font-size: 1rem;
}
.withPadding {
  padding: var(--padding-top);
}
.g-with-safe-padding {
  padding-top: var(--standard-margin);
  padding-right: calc(var(--standard-margin) + var(--ion-safe-area-right));
  padding-bottom: var(--standard-margin);
  padding-left: calc(var(--standard-margin) + var(--ion-safe-area-left));
}
.g-non-scroll-content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.withTriplePadding {
  padding: var(--triple-margin);
}
.g-content-with-safe-padding {
  --padding-start: var(--ion-safe-area-left);
  --padding-end: var(--ion-safe-area-right);
}
.g-content-with-padding {
  --padding-top: var(--standard-margin);
  --padding-end: calc(var(--standard-margin) + var(--ion-safe-area-right));
  --padding-bottom: var(--standard-margin);
  --padding-start: calc(var(--standard-margin) + var(--ion-safe-area-left));
}
.g-centered-box-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.g-centered-content {
  max-width: 600px;
}
.g-avatar-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70px;
}
.autoOverflowContainer {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.withColumnDirection {
  display: flex;
  flex-direction: column;
}
.withCenteredColumnContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.withLeftAlignedColumnContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.withRowDirection {
  display: flex;
  flex-direction: row;
}
.rowWithSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.rowWithCenterAlignedItems {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rowWithCenterJustifiedItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.rowWithEndJustifyContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.rowWithTopAlignedItems {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.with10PercentWidth {
  width: 10% !important;
}
.with30PercentWidth {
  width: 30% !important;
}
.with40PercentWidth {
  width: 40% !important;
}
.with50PercentWidth {
  width: 50% !important;
}
.with65PercentWidth {
  width: 65% !important;
}
.with75PercentWidth {
  width: 75% !important;
}
.with90PercentWidth {
  width: 90% !important;
}
.with100PercentWidth {
  width: 100% !important;
}
.with100PercentHeight {
  height: 100% !important;
}
.withMaxWidth800px {
  max-width: 800px !important;
}
.withMaxWidth600px {
  max-width: 600px !important;
}
.withMaxWidth400px {
  max-width: 400px !important;
}
.withMaxWidth200px {
  max-width: 200px !important;
}
.withMaxWidth150px {
  max-width: 150px !important;
}
.with2RemWidth {
  width: 2rem !important;
}
.with4RemWidth {
  width: 4rem !important;
}
.withBoldText {
  font-weight: bold !important;
}
.withUnderlinedText {
  text-decoration: underline !important;
}
.withMonospaceText {
  font-family: "Lucida Console", "Menlo", "Monaco", "Courier", monospace !important;
}
.withStandardTopMargin {
  margin-top: var(--standard-margin) !important;
}
.withStandardMargin {
  margin: var(--standard-margin) !important;
}
.withDoubleTopMargin {
  margin-top: var(--double-margin) !important;
}
.withTripleTopMargin {
  margin-top: var(--triple-margin) !important;
}
.withSmallBottomMargin {
  margin-bottom: var(--small-margin) !important;
}
.withSmallTopMargin {
  margin-top: var(--small-margin) !important;
}
.withStandardBottomMargin {
  margin-bottom: var(--standard-margin) !important;
}
.withFormItemBottomMargin {
  margin-bottom: 24px !important;
}
.withDoubleLeftMargin {
  margin-left: var(--double-margin) !important;
}
.withDoubleBottomMargin {
  margin-bottom: var(--double-margin) !important;
}
.withTripleBottomMargin {
  margin-bottom: var(--triple-margin) !important;
}
.withQuadrupleBottomMargin {
  margin-bottom: calc(4 * var(--standard-margin)) !important;
}
.withStandardLeftMargin {
  margin-left: var(--standard-margin) !important;
}
.withStandardRightMargin {
  margin-right: var(--standard-margin) !important;
}
.withDoubleRightMargin {
  margin-right: var(--double-margin) !important;
}
.withSmallLeftMargin {
  margin-left: var(--small-margin) !important;
}
.withSmallRightMargin {
  margin-right: var(--small-margin) !important;
}
.withSmallPadding {
  padding: var(--small-margin) !important;
}
.withStandardPadding {
  padding: var(--standard-margin) !important;
}
.withDoublePadding {
  padding: var(--double-margin) !important;
}
.withLeftTextAlign {
  text-align: left !important;
}
.withCenteredTextAlign {
  text-align: center !important;
}
.withRightTextAlign {
  text-align: right !important;
}
.withRedBorder {
  border: 1px solid red;
}
.withGrayBorder {
  border: 1px solid gray;
}
.withOrangeBorder {
  border: 1px solid #f16901;
}
.withBlueBorder {
  border: 1px solid #7079e0;
}
.withBlackBorder {
  border: 1px solid black;
}
.withErrorDanger {
  background-color: var(--ion-color-danger);
}
.withSuccessBackground {
  background-color: var(--ion-color-success);
}
.withItalicText {
  font-style: italic;
}
.withPointerCursor {
  cursor: pointer;
}
.withRoundedBorder {
  padding: var(--standard-margin);
  border: 1px solid var(--border-color-light);
  border-radius: 8px;
}
.g-with-flex-1 {
  flex: 1;
}
.hidden {
  display: none;
}
.app-page-public {
  /*max-width: 800px;*/
  /*margin: auto;*/
  /*-webkit-box-shadow: 0 20px 20px 5px rgba(0,0,0,0.19);*/
  /*-moz-box-shadow: 0 20px 20px 5px rgba(0,0,0,0.19);*/
  /*box-shadow: 0 20px 20px 5px rgba(0,0,0,0.19);*/
  background-color: white;
}
.app-page-admin {
  /*width: 100%;*/
}
.bottomButtonWrapper {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bottomButtonWrapperHorizontal {
  margin-top: var(--double-margin);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.absolutePositionCenteredFlexContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row {
  display: flex;
}
.value {
  margin-left: 5px;
  font-weight: bold;
}
.smallButton {
  height: var(--small-button-height);
  font-size: .8rem;
}
.section {
  margin-bottom: var(--double-margin);
}
.sectionCaption {
  font-size: 1.2rem;
  color: var(--ion-color-medium-shade);
  margin-bottom: var(--standard-margin);
}
.smallText {
  font-size: var(--small-text-size);
}
.xsmallText {
  font-size: var(--xsmall-text-size);
}
.xxsmallText {
  font-size: var(--xxsmall-text-size);
}
.largeText {
  font-size: var(--large-text-size);
}
.lightText {
  color: var(--text-color-light);
}
.linkText {
  color: var(--text-color-link);
  text-decoration: underline;
  cursor: pointer;
}
.size0 {
  width: 0;
  height: 0;
}
.formButtonWrapper {
  margin-top: var(--double-margin);
  text-align: center;
}
.giftCardOuterWrapper {
  margin: var(--standard-margin);
  width: 100%;
  max-width: 400px;
}
.searchBarWrapper {
  border: 2px solid var(--border-color-light);
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.searchBarRow0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.clearFilterIcon {
  color: var(--text-color-light);
}
.searchBar {
  padding: 0;
}
.searchBarSearchIcon {
  width: 1.3rem;
  height: 1.3rem;
  color: var(--text-color-light);
  margin-left: 2px;
  margin-right: var(--standard-margin);
}
.searchBar > div > input {
  border: none !important;
  background: none !important;
  background-position-x: 0 !important;
  background-position-y: 0;
  box-shadow: none !important;
}
.expandFilterIcon {
  width: 1.3rem;
  height: 1.3rem;
  margin: 0 var(--standard-margin);
  color: var(--text-color-light);
}
.bareIonItem {
  --padding-start: 0;
  --box-shadow: none;
  --margin-bottom: 0;
}
.inlineEditIcon {
  margin-left: var(--standard-margin);
  color: var(--text-color-light);
}
.attributeList {
}
.attributeListRow {
  margin-bottom: 4px;
}
.attributeListLabel {
  color: var(--text-color-light);
  margin-right: var(--standard-margin);
}
.attributeListValue {
}
.inlineCheckbox {
  width: .7rem;
  height: .7rem;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 4px;
}
.handleGlyph {
  font-weight: bold;
  color: var(--ion-color-handle-glyph);
}
.inlineWarnIcon {
  color: var(--ion-color-warning);
  margin-right: 5px;
}
.g_bulleted-list-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .7rem;
  color: var(--ion-color-medium)
}
.tag-row .tag-row-button {
  margin: 0;
}

.edit-tag-form .modal-buttons {
  display: flex;
  width: 48%;
  margin-top: 20px;
}

.ion-modal {
  padding: 20px;
}

.g-inline-copy-to-clipboard-icon {
  color: var(--ion-color-medium);
  margin-left: var(--small-margin);
}

.g-text-paragraph-button {
  text-align: left;
  text-transform: none;
  font-weight: normal;
  letter-spacing: normal;
  /*display: block;*/
  white-space: normal;
  color: var(--text-color-link);
  text-decoration: underline;
  --padding-start: 0;
  --padding-end: 0;
  height: auto;
  margin: 0;
}
.g-gray-text-button-label {
  color: var(--text-color-light);
  font-weight: normal;
  text-transform: none;
}
/* ---------------------------------------------------------------------------------------------------- */
@media (min-width: 540px) {
  .viewWrapper {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-top: var(--double-margin);
    max-width: 800px;
  }
}
