.formItem {
}
.formItemWithBottomMargin {
  margin-bottom: 15px;
}
.formLabel {
  font-size: .8rem;
  color: var(--text-color-light);
}
.inputWrapperWithNoError {
  background-color: white;
  border: 1px solid var(--border-color-input);
  padding: 3px;
  width: 100%;
  border-radius: 5px;
  margin: 2px 0;
}
.inputWrapperWithError {
  background-color: white;
  border: 2px solid var(--border-color-form-error);
  padding: 0 3px;
  width: 100%;
  border-radius: 5px;
  margin: 2px 0;
}
.inputWrapperWithSuccess {
  border: 2px solid var(--ion-color-success-shade);
  padding: 0 3px;
  width: 100%;
  border-radius: 5px;
  margin: 2px 0;
}
.inputWrapperErrorMessage {
  color: var(--border-color-form-error);
  font-size: var(--small-text-size);
}
.inputWrapperSuccessMessage {
  color: var(--ion-color-success);
  font-size: var(--small-text-size);
}
