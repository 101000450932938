.chat .message-wrapper {
  margin-bottom: var(--standard-margin);
  max-width: 90%;
  display: flex;
  flex-direction: column;
}
.chat .message-wrapper-left .status-text {
  text-align: left;
}
.chat .message-wrapper-system .status-text {
  text-align: left;
}
.chat .message-wrapper-right .status-text {
  text-align: right;
  justify-content: flex-end;
}
.chat .message-wrapper-system {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  align-items: center;
}
.chat .message {
  background-color: #fff;
  /* padding-bottom: var(--small-margin); */
  font-size: .9rem;
  border-radius: 8px;
  box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
  margin-bottom: var(--small-margin);
  max-width: max-content;
}
.chat .message-wrapper .message-left {
  margin-right: 70px;
  border-top-left-radius: 0;
  display: flex;
  flex-direction: column;
}
.chat .message-wrapper .message-right {
  margin-left: 70px;
  border-bottom-right-radius: 0;
  background-color: #5368d12b;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}
.chat .message-system {
  background-color: #e8e8e8;
}
.chat .status-text {
  display: flex;
  font-size: .6rem;
  color: var(--text-color-light);
  margin-bottom: var(--standard-margin);
}
.chat .attachment-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--small-margin);
  margin-bottom: var(--small-margin);
}
.chat .view-width-70 {
  max-width: 70vw;
}
.chat .attached-image-left {
  border-top-right-radius: 8px;
  overflow: hidden;
  max-width: 300px;
}
.chat .attached-image-right {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  overflow: hidden;
  max-width: 300px;
}
.chat .attachments {
  padding: var(--small-margin);
}
.chat .attachments-left {
  display: flex;
  flex-direction: column;
}
.chat .attachments-right {
  display: flex;
  flex-direction: column;
}
