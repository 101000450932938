.storedProductListRow {
  --padding-start: 0;
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --min-height: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  /*border: 1px solid #756ad7;*/
}
.storedProductListHeaderRow {
  border-bottom: 1px solid var(--border-color-light);
  padding-bottom: 2px;
  margin-bottom: var(--standard-margin);
}
.storedProductListRowItemContent {
  /*border: 1px solid #1a8ed7;*/
  width: 100%;
  padding-top: var(--standard-margin);
  padding-bottom: var(--standard-margin);
}
.storedProductListHeaderRowItemContent {
  /*border: 1px solid #1a8ed7;*/
  width: 100%;
  padding-bottom: 0;
}
.storedProductListRow0 {
  display: flex;
  flex-direction: row;
  /*justify-content: space-between;*/
  align-items: center;
  width: 100%;
  /*border: 1px solid #1a8ed7;*/
}
.storedProductListRowCol {
  flex: 1;
  padding-right: var(--standard-margin);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.storedProductListRowCol0 {
}
.storedProductListRow0Col1 {
  /*flex: .8;*/
}
.storedProductListRow0Col2 {
}
.storedProductListRow0Col3 {
  flex: 2;
}
.storedProductListRow0Col4 {
  padding-right: 0;
  /*border: 1px solid #1a8ed7;*/
}
.storedProductListRow0Col5 {
  padding-right: 0;
  /*border: 1px solid #1a8ed7;*/
}
.storedProductListRowCol1 {
  flex: 3;
  padding-top: var(--standard-margin);
  height: 100%;
}
.storedProductListRowCol1Row0 {

}
.storedProductListRowStoredProductName {
  color: #676e81;
  font-size: 1.2em;
  /*border: 1px solid #479156;*/
}
.storedProductListRowStoredProductUrl {
  margin-top: 5px;
  font-size: .8em;
  color: #a0abc1;
}
.storedProductListRowLinkIcon {
  width: var(--standard-margin);
  margin-right: 5px;
}
.storedProductListRowImg {
  width: 120px;
}
.storedProductListRowButton {
  margin-left: 15px;
}
.storedProductListRowVisitIcon {
  margin-left: var(--standard-margin);
}

/* ------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 600px) {
  /*.productListItemCol4 {*/
  /*  display: none;*/
  /*}*/
}
@media screen and (max-width: 1200px) {
  .storedProductListRowCol {
    font-size: .8rem;
  }
  .storedProductListRow0Col4 {
    display: none;
  }
}