.new-attachment-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: var(--standard-margin);
}
.new-attachment-section .row-icon {
  height: 30px;
  width: 30px;
  margin-right: var(--standard-margin);
}
