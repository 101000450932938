.action-modal {
  flex: 1;
  background-color: white;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  left: 0;
  width: 100%;
  z-index: 101;
}
.action-modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--ion-color-light);
  padding: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.action-modal .header-title {
  font-size: 1rem;
  color: var(--ion-color-medium-shade);
  margin-left: var(--standard-margin);
}
.action-modal .close-button {
  color: var(--ion-color-medium);
}
.action-modal .child-container {
  display: flex;
  flex-direction: column;
  margin: var(--standard-margin);
}
.action-modal .menu {
  overflow: auto;
  margin-bottom: var(--standard-margin);
}
.action-modal .item {
  border-bottom: none;
}
.action-modal-backdrop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
