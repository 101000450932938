.send-gift-form {
  display: flex;
  flex-direction: column;
}
.send-gift-form .current-value {
  display: flex;
  flex-direction: column;
}
.send-gift-form .current-value .info-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: var(--double-margin);
}

/* ---------------------------- Recipient Step ----------------------- */
.send-gift-form .recipient-step {
  margin: 0 var(--standard-margin);
}
.send-gift-form .recipient-step .select-scope {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.send-gift-form .recipient-step .select-scope .button {
  width: auto;
  height: auto;
  --padding-start: 6px;
  --padding-end: 6px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--border-color-light);
  border-radius: 6px;
}
.send-gift-form .recipient-step .select-scope .button-selected {
  border-width: 2px;
  border-style: solid;
  border-color: var(--ion-color-primary);
  border-radius: 6px;
}
.send-gift-form .recipient-step .select-scope .button-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  color: var(--ion-color-medium);
}
.send-gift-form .recipient-step .select-scope .button-icon {
  font-size: 1.5rem !important;
}
.send-gift-form .recipient-step .select-scope .button-icon-selected {
  font-size: 1.5rem !important;
  color: var(--ion-color-primary);
}
.send-gift-form .recipient-step .select-scope .button-label {
  font-size: 0.7rem;
  text-transform: none;
  font-weight: normal;
  margin-top: 5px;
}
.send-gift-form .recipient-step .select-scope .button-label-selected {
  font-size: 0.7rem;
  text-transform: none;
  font-weight: normal;
  margin-top: 5px;
  color: var(--ion-color-primary);
}
.send-gift-form .recipient-step .find-recipient-form {
  height: 40vh;
  /*border: 2px solid greenyellow;*/
}

/* ---------------------------- Gift Step ----------------------- */
.send-gift-form .gift-step {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.send-gift-form .gift-step .gift-list-wrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-top: var(--double-margin);
}

/* ---------------------------- Message Step ----------------------- */
.send-gift-form .message-step .attachment-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px dashed var(--ion-color-light-shade);
  margin-top: var(--small-margin);
}
.send-gift-form .message-step .attachment-section .attachment-button {
  align-self: flex-end;
  --padding-start: 5px !important;
  --padding-end: 5px !important;
}
.send-gift-form .message-step .attachment-section .chat-attachment-preview {
  display: flex;
  flex: 1;
  padding: var(--small-margin);
}
.send-gift-form .message-step .attachment-section .chat-attachment-preview-media {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--ion-color-light-shade);
  max-width: 180px;
  margin-top: var(--small-margin);
  position: relative;
  align-self: flex-end;
}

/* ---------------------------- Confirm Step ----------------------- */
.send-gift-form .confirm-step {
  margin-top: var(--double-margin);
}
.send-gift-form .confirm-step .section {
  margin-bottom: var(--double-margin);
}
.send-gift-form .confirm-step .section .section-icon {
  width: 22px;
  height: 22px;
  margin-right: 6px;
  color: var(--ion-color-medium);
}
.send-gift-form .confirm-step .section .section-content {
  margin-left: 28px;
  margin-bottom: var(--double-margin);
}
.send-gift-form .confirm-step .section-caption {
  color: var(--ion-color-medium);
}
.send-gift-form .confirm-step .section-text {
  margin-left: 28px;
  margin-bottom: var(--standard-margin);
}

/* ---------------------------- Transfer Finished Step ----------------------- */
.send-gift-form .purchase-transfer-finished-step .title {
  font-size: 1.2rem;
  color: var(--text-color-light);
  margin-top: var(--standard-margin);
  margin-bottom: var(--triple-margin);
}
.send-gift-form .purchase-transfer-finished-step .pop-up {
  padding: var(--standard-margin);
}
.send-gift-form .purchase-transfer-finished-step .gift-link-label {
  font-size: small;
  color: var(--text-color-light);
  margin-top: var(--double-margin);
}
.send-gift-form .purchase-transfer-finished-step .gift-link {
  margin-bottom: var(--standard-margin);
  border: 1px solid var(--border-color-light);
  border-radius: 6px;
  padding: var(--standard-margin);
  text-align: center;
  /*font-weight: bold;*/
}
.send-gift-form .purchase-transfer-finished-step .warning {
  color: var(--text-color-light);
  font-size: small;
  /*border: 1px solid var(--border-color-light);*/
  /*border-radius: 4px;*/
  /*padding: var(--small-margin);*/
  margin-bottom: var(--double-margin);
}
