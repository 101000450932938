.transaction-page .conversion-tip {
  font-size: var(--small-text-size);
  border: 1px solid var(--border-color-light);
  max-width: 150px;
  padding-top: var(--small-margin);
  padding-right: var(--small-margin);
  padding-bottom: var(--small-margin);
  padding-left: var(--small-margin);
  border-radius: 6px;
  color: var(--text-color-medium-light);
  background-color: var(--border-color-xlight);
}
.transaction-page .conversion-tip .icon {
  margin-right: var(--standard-margin);
  font-size: 20px;
  vertical-align: middle;
}
.transaction-page .reward-item {
  margin-bottom: var(--double-margin);
}
.transaction-page .card-section {
  padding-left: var(--standard-margin);
  padding-right: var(--standard-margin);
}
.transaction-page .section-icon {
  width: 22px;
  height: 22px;
  margin-right: 6px;
  color: var(--ion-color-medium);
}
.transaction-page .section-header {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: var(--ion-color-medium);
}
.transaction-page .section-content {
  margin-left: 28px;
  margin-bottom: var(--double-margin);
}
.transaction-page .token-image {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  margin-right: var(--standard-margin);
}
.transaction-page .reward-item {
  margin-bottom: var(--triple-margin);
}
.reward-item > .row0 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.reward-item > .content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.reward-item > .see-more {
  padding-top: var(--standard-margin);
  color: var(--text-color-medium-light);
  text-decoration: underline;
  cursor: pointer;
}
.reward-item > .details-section {
  padding-top: var(--standard-margin);
}
