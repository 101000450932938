.event-form .category-dropdown {
  /* background-color: var(--ion-color-light); */
  border-right: 1px solid var(--ion-color-light-shade);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--small-margin);
  cursor: pointer;
}
.pop-over .popover-content {
  --ion-grid-columns: 3;
  --ion-grid-column-padding: 2vw;
  --width: auto;
}
.event-form .category-dropdown-text {
  color: var(--text-color-light);
  font-size: .8rem;
}
.event-form .category-icon {
  color: var(--text-color-light);
  font-size: 24px;
}
.event-form .category-dropdown-icon {
  color: var(--text-color-light);
  margin-left: var(--small-margin);
}
.event-form .form-buttons {
  text-align: center;
  margin-bottom: var(--standard-margin);
}

.event-form .reminder-form {
  margin-bottom: var(--standard-margin);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.event-form .reminder-form .delete-icon {
  color: var(--text-color-light);
}
.event-form .reminder-form .delete-spinner {
  color: var(--text-color-light);
  width: 1.2rem;
  height: 1.2rem;
  margin-left: var(--double-margin);
}
