.event-list-item {
  padding: var(--standard-margin);
  border: 1px solid var(--border-color-light);
  border-radius: 10px;
  margin-bottom: var(--standard-margin);
}
.event-list-item .notes {
  margin-top: var(--small-margin);
  color: #648bff;
  font-style: italic;
}
