.welcome-page .logo-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: var(--triple-margin);
}
.welcome-page .logo-section .logo-wrapper {
  width: 100px;
  height: 100px;
  margin-bottom: var(--triple-margin);
}
.welcome-page .logo-section .welcome-text {
  color: #d0d0d0;
  font-size: 1.5rem;
  margin-bottom: var(--double-margin);
}
.welcome-page .logo-section .app-name {
  color: var(--ion-color-primary);
  font-size: 2rem;
  font-weight: bold;
}
.welcome-page .logo-section .tagline {
  color: var(--ion-color-medium);
}
.welcome-page .join-button-section {
  margin-bottom: 40px;
}
