.new-chat-message {
 /* We're handling this background color with a style helper `containerColor` */
}
.new-chat-message.in-chat {
  flex-direction: row;
  background-color: #fafafa;
  padding: 8px;
}
.new-chat-message .input-wrapper {
  display: flex;
  flex-direction: column-reverse;
  background-color: white;
  border: 1px solid var(--border-color-input);
  padding: var(--small-margin);
  width: 100%;
  border-radius: 8px;
}
.new-chat-message .input-wrapper.in-chat {
  flex-direction: row;
}
.new-chat-message .gift-flow-format {
  flex-direction: column-reverse !important;
  border-top: 1px dashed var(--ion-color-light-shade);
  margin-top: var(--small-margin);
}
.new-chat-message .attachment-button {
  --padding-start: 5px !important;
  --padding-end: 5px !important;
  align-self: flex-end;
}
.new-chat-message .text-area {
  --padding-start: 8px;
  --padding-top: 8px;
}
.new-chat-message .send-button {
  --padding-end: 5px !important;
  align-self: flex-end;
}
.new-chat-message .send-button-icon {
  width: 22px;
  height: 22px;
}
.new-message-attachment-section {
  display: flex;
  flex-direction: column;
  flex: 1;
}
