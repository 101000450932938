.users-page .userHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.users-page .actions-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: var(--small-margin);
}
