.wish-list {
  padding-top: 0;
  padding-bottom: 0;
}
.wish-list .list-wrapper {
  overflow: auto;
}

.wish-list .info-icon {
  font-size: 1.6rem;
  padding-right: var(--standard-margin);
}

.wish-list .help-text {
  display: flex;
  align-items: center;
  font-size: var(--small-text-size);
  border: 1px solid var(--border-color-light);
  padding-top: var(--small-margin);
  padding-right: var(--small-margin);
  padding-bottom: var(--small-margin);
  padding-left: var(--small-margin);
  border-radius: 6px;
  color: var(--text-color-light);
  /* background-color: var(--border-color-xlight); */
}

/* --------------------------- WishListItem ------------------------- */
.wish-list-item .wish-item {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
  border-bottom: 1px solid #dedede;
}
.wish-list-item .item-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  width: 100%;
}
.wish-list-item .wish-title {
  flex: 1;
}
.wish-list-item .title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.wish-list-item .notes-row {
  margin-top: var(--standard-margin);
  font-size: .9rem;
  color: var(--text-color-light);
}
.wish-list-item .product-url-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: var(--small-margin);
}
.wish-list-item .product-link {
  flex: 1;
  font-size: small;
  color: var(--text-color-light);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wish-list-item .row-icon {
  margin-right: var(--small-margin);
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.wish-list-item .contact-button {
}
.wish-list .in-list-edit-form-wrapper {
  margin: var(--standard-margin) 0;
}
/* --------------------------- NewWishForm ------------------------- */
.wish-list .wish-form {
  border: 2px solid var(--ion-color-light-shade);
  border-radius: 8px;
  padding: var(--standard-margin);
}
.wish-list .wish-form .close-button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.wish-list .wish-form .button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: var(--double-margin);
}
