.mimble-token-image {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  padding: 4px;
  border: 1px solid var(--ion-color-medium);
  -webkit-box-shadow: 2px 3px 3px -1px rgba(0,0,0,0.12);
  -moz-box-shadow: 2px 3px 3px -1px rgba(0,0,0,0.12);
  box-shadow: 2px 3px 3px -1px rgba(0,0,0,0.12);
  background-color: white;
}
