/* --------------------------------------- Horizontal -------------------------------------- */
.form-radio-group .items-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  /*margin-top: var(--standard-margin);*/
}
.form-radio-group .item-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  /*border: 1px dotted gray;*/
  cursor: pointer;
}
.form-radio-group .first-item-horizontal {
  margin-left: 0;
}
.form-radio-group .icon-horizontal {
  margin-right: 6px;
  cursor: pointer;
}

/* --------------------------------------- Vertical -------------------------------------- */
.form-radio-group .items-vertical {
  display: flex;
  flex-direction: column;
}
.form-radio-group .item-vertical {
  display: flex;
  flex-direction: row;
  align-items: center;
  /*border: 1px dotted gray;*/
  margin-top: .4rem;
  cursor: pointer;
}
.form-radio-group .first-item-vertical {
  margin-top: 0;
}
.form-radio-group .icon-vertical {
  margin-right: .4rem;
  cursor: pointer;
}

/* --------------------------------------- All Layouts -------------------------------------- */
.form-radio-group .icon-disabled {
  color: var(--text-color-light);
}
