.sign-up-form.tfa-input-mode {
  background-color: #faf9f9;
}
.sign-up-form .sectionCaption {
  font-size: 1.2rem;
  color: var(--ion-color-medium);
  margin-bottom: var(--standard-margin);
}
.sign-up-form .code-activity-spinner {
  color: var(--ion-color-medium);
  margin-right: var(--standard-margin);
}
.sign-up-form .tfa-input-section {
  margin-top: var(--double-margin);
  padding: var(--standard-margin);
  border: 3px solid #b5e295;
  border-radius: 10px;
  background-color: white;
}
