.pageMessagesWrapper {
  margin: var(--standard-margin);
}
.pageMessages {
  display: flex;
  padding: var(--standard-margin);
  margin: var(--standard-margin) 0;
  border-radius: .5rem;
}
.pageMessagesNotices {
  background-color: #cbeaca;
  color: #60945e;
}
.pageMessagesWarnings {
  background-color: #ccdcff;
  color: #5871a9;
}
.pageMessagesErrors {
  background-color: #ffe268;
  color: #826a04;
}
.pageMessagesCol0 {
  display: flex;
  align-items: center;
  padding-right: var(--standard-margin);
}

.message-inner-wrapper {
  display: flex;
  justify-content: space-between;  
  flex: 1;
}

.pageMessagesCol1 {
  display: flex;
  align-items: center;
}
.pageMessagesCol2 {
  display: flex;
  align-items: center;
}
.pageMessageIcon {
  width: 2rem;
  height: 2rem;
}
.closeIcon {
  width: 1rem;
  height: 1rem;
  color: var(--text-color-light);
}
