.modelEventList {
}
.modelEventItem {
  font-size: .8rem;
  margin-bottom: var(--standard-margin);
}
.modelEventItemTime {
  font-weight: bold;
}
.modelEventItemMessage {
  color: var(--text-color-light);
}
.modelEventItemMessageWarning {
  color: orange;
}
.modelEventItemMessageError {
  color: red;
}
