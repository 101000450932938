.user-account-profile-tab {
  padding: var(--standard-margin);
}
.user-account-profile-tab .avatar-row {
  display: flex;
  flex-direction: row;
}
.user-account-profile-tab .avatar-col {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  margin-top: 18px;
}
.user-account-profile-tab .avatar {
  width: 80px;
  height: 80px;
}
