.product-option-item {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: var(--standard-margin);
  text-align: center;
  margin-bottom: var(--standard-margin);
  width: 100%;
  cursor: pointer;
}
.product-option-item.disabled {
  cursor: default;
  background-color: #f5f4f4;
}
.product-option-item.disabled {
  background-color: #efefef;
}
.product-option-item.selected {
  border: 3px solid #4a6fc6;
  background-color: #bed1ff;
}
.product-option-item .line1 {
  font-size: 4rem;
  font-weight: bold;
}
.product-option-item .line1-disabled {
  color: var(--text-color-light);
}
.product-option-item .line1-currency {
  color: var(--text-color-light);
  font-size: 1rem;
  margin-right: var(--standard-margin);
  font-weight: normal;
}
.product-option-item .reward-info {
  color: #939dd0;
}
.product-option-item .reward-info-disabled {
  color: var(--text-color-light);
}
