.inline-message {
  display: flex;
  flex-direction: row;
  margin: var(--standard-margin) 0;
  border: 1px solid;
  padding: var(--small-margin);
  border-radius: 10px;
}
.inline-message .icon {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin-top: 2px;
  margin-right: var(--small-margin);
}
