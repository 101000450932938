.product-grid {
  --ion-grid-columns: 1;
}

.product-grid .col {
  --ion-grid-column-padding: 1vw;
  margin-bottom: 2.3vh;
}

.product-grid .info {
  color: var(--text-color-light);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 6px;
}

.product-grid .merchant-logo {
  min-width: 30px;
  max-width: 50px;
  display: none;
}

/* ----------------------------------------- screen size dependent classes ------------------------------------------*/
@media only screen and (min-width: 300px) {
  .product-grid {
    --ion-grid-columns: 2;
  }
}

@media only screen and (min-width: 400px) {
  .product-grid {
    --ion-grid-columns: 3;
  }
}

@media only screen and (min-width: 800px) {
  .product-grid {
    --ion-grid-columns: 4;
  }
  .product-grid .merchant-logo {
    display: inherit;
  }
}

@media only screen and (min-width: 1100px) {
  .product-grid {
    --ion-grid-columns: 5;
  }
}
