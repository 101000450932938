.in-app-message-modal .modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.in-app-message-modal .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: var(--standard-margin);
}
.in-app-message-modal .announce-icon-wrapper .announce-icon {
  width: 30px;
  height: 30px;
  color: #eae8e8;
}
.in-app-message-modal .header-caption {
  color: var(--text-color-light);
  font-size: 1.2rem;
}
.in-app-message-modal .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.in-app-message-modal .title {
  color: var(--text-color-card-balance);
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: var(--standard-margin);
}
.in-app-message-modal .body-text {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: var(--standard-margin);
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 8px;
  margin: var(--standard-margin);
}
.in-app-message-modal .form-button-wrapper {
  text-align: center;
}
