.merchants-page .merchant-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.merchants-page .addMerchantIcon {
  width: 2.2rem;
  height: 2.2rem;
  padding: 0 var(--double-margin);
  color: var(--text-color-light);
}
.merchants-page .actions-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: var(--small-margin);
}
