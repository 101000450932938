.gift-flow-stepper.small {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /*background-color: #fafafa;*/
  background-color: #96db5d;
  color: white;
  /*border: 2px solid yellowgreen;*/
  padding: 4px 8px;
}
.gift-flow-stepper.small .arrowIcon {
  /*margin: 0 var(--standard-margin);*/
  color: #ffffff78;
}
.gift-flow-stepper.small .step {
  font-size: .85rem;
  color: #ffffffd6;
}
.gift-flow-stepper.small .step.active {
  display: flex;
  align-items: center;
  font-size: .85rem;
  color: #ffffff;
  font-weight: bold;
}
.gift-flow-stepper.small .step.active .icon {
  margin-right: 3px;
  color: #ffffffd6;
}
.gift-flow-stepper.small .step.active .label {
  /*margin-bottom: 14px;*/
}
