.events-section {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.events-section .new-event-button-section {
  text-align: right;
  margin-bottom: var(--standard-margin);
}
.events-section .event-list {
  margin: 0 var(--standard-margin);
}
