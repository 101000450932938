.legal-section .non-refundable-notice {
  margin-top: var(--double-margin);
}

.legal-section .non-refundable-notice {
  margin: var(--standard-margin);
  margin-left: 0;
  padding: 2px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: 1px solid var(--border-color-light);
}

.legal-section .badges {
  display: flex;
  justify-content: space-between;
}

.legal-section .powered-by {
  display: flex;
  flex-direction: column;
  max-width: 100px;
  color: var(--text-color-light);
}

.legal-section .powered-by .powered-by-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
  color: var(--text-color-light);
  font-size: var(--xsmall-text-size);
}

.legal-section .non-refundable-notice .warn-icon {
  width: 1.3rem;
  height: 1.3rem;
  color: var(--text-color-light);
  margin-right: var(--small-margin);
}

.legal-section .non-refundable-notice .text {
  color: var(--text-color-light);
  font-size: var(--small-text-size);
  padding-right: var(--small-margin);
}
