.object-info {
  margin-top: var(--double-margin);
}
.object-info .section {
  margin-bottom: var(--double-margin);
}
.object-info .section-icon {
  width: 22px;
  height: 22px;
  margin-right: 6px;
  color: var(--ion-color-medium);
}
.object-info .section-content {
  margin-left: 28px;
  margin-bottom: var(--double-margin);
}
.object-info .section-caption {
  color: var(--ion-color-medium);
}
.object-info .section-text {
  margin-left: 28px;
  margin-bottom: var(--standard-margin);
}
