.purchasesListFilterHeaderWrapper {
  padding-top: var(--standard-margin);
  padding-left: var(--standard-margin);
  margin-bottom: var(--standard-margin);
}
.purchasesListFilterRow0 {
  /*display: flex;*/
  /*flex-direction: row;*/
  /*align-items: center;*/
}
.purchaseListHeaderAddPurchaseButtons {
}
.purchaseListAddPurchaseIcon {
  width: 1.3rem;
  height: 1.3rem;
  padding: 0 var(--double-margin);
  color: var(--text-color-light);
}
.purchaseListCreatePurchaseIcon {
  width: 1.3rem;
  height: 1.3rem;
  padding-right: var(--double-margin);
  color: var(--text-color-light);
}
.purchaseListFilterExpandedSection {
  display: flex;
  margin-left: var(--standard-margin);
  margin-right: var(--standard-margin);
  margin-bottom: var(--double-margin);
  justify-content: center;
}
