.chat {
  position: relative;
}
.chat.full-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.chat.embedded {
  height: 70vh;
  display: flex;
  flex-direction: column;
}
.chat.embedded .caption {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  background-color: #efefef;
  border: 1px solid #efefef;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: var(--small-margin);
}
.chat .messages-content {
  flex: 1;
  overflow: auto;
  padding: 0 var(--standard-margin);
}
.chat .received-message-row {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--standard-margin);
}
.chat .sent-message-row {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: var(--standard-margin);
}
.chat .system-message-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chat .from-user-avatar {
  margin-right: var(--standard-margin);
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
