.context-help-modal .modal-wrapper {
  border-radius: 12px;
  max-width: 90vw;
  max-height: 90vh;
  padding: var(--standard-margin);
}
.context-help-modal .modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.context-help-modal .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: var(--standard-margin);
}
.context-help-modal .header-caption {
  color: var(--text-color-light);
  font-size: 1.1rem;
}
