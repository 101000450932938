.request-info-section .request-wrapper {
  text-align: center;
  padding-right: var(--standard-margin);
  margin: var(--double-margin) 0;
}
.request-info-section .request-string {
  /*margin: var(--standard-margin) 0;*/
  font-family: "Lucida Console", "Menlo", "Monaco", "Courier", monospace;
  font-size: .7rem;
  text-align: center;
  width: 100%;
}
.request-info-section .crypto-address {
  font-family: "Lucida Console", "Menlo", "Monaco", "Courier", monospace;
  font-size: .7rem;
  background-color: #e2e2e2;
  color: #404040;
  padding: 2px;
}
.request-info-section .expires-in-section {
  margin-top: var(--double-margin);
  font-size: var(--small-text-size);
}
.request-info-section .expires-in-section .text {
  margin-top: 3px;
  font-size: var(--small-text-size);
  color: var(--text-color-light);
}
