.info-section {
  margin: var(--standard-margin);
  margin-top: var(--double-margin);
}
.info-section .birthday-icon-fontsize-small {
  font-size: 1rem !important;
}
.info-section .contact-info-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--standard-margin);
}
.info-section .contact-info-line .contact-info-line-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info-section .invitation-info {
  color: var(--text-color-light);
  font-size: var(--small-text-size);
}
