.marketplace-page .empty-list-content {
  text-align: center;
  padding: var(--triple-margin);
  color: var(--text-color-light);
}

/* --------------------------- Header -------------------------------- */
.marketplace-header {
  border: 1px solid var(--border-color-light);
  border-radius: 5px;
  display: flex;
  margin: var(--standard-margin);
  overflow: hidden;
}
.marketplace-header .category-dropdown {
  background-color: var(--ion-color-light);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--standard-margin);
  cursor: pointer;
}
.marketplace-header .category-dropdown-text {
  color: var(--text-color-light);
  font-size: .8rem;
}
.marketplace-header .category-dropdown-icon {
  color: var(--text-color-light);
  margin-left: var(--small-margin);
}
.marketplace-header .search-input {
    margin-left: var(--small-margin);
}
.marketplace-header .search-button-wrapper {
  background-color: #7bc4ef;
}
.marketplace-header .search-button-wrapper-disabled {
  background-color: var(--ion-color-light);
}
.marketplace-header .search-button-icon {
  color: white;
}
.marketplace-header .search-button-icon-disabled {
  color: var(--ion-color-medium);
}
