.top-nav-bar {
  display: flex;
  position: relative;
  justify-content: space-between;
  overflow: auto;
}
.top-nav-bar .tab {
  flex: 1;
  color: var(--top-nav-bar-text-color-inactive);
  padding: var(--standard-margin) var(--small-margin);
  border-bottom: 1px solid var(--border-color-light);
  font-size: .85rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.top-nav-bar .active-tab {
  color: #3b5998;
  border-bottom: 2px solid var(--top-nav-bar-bottom-border-color-active);
}

.top-nav-bar .count-text {
  font-size: .65rem;
}
