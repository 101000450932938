.dashboard-widget {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: space-between;*/
  /*flex: 1;*/
  border: 1px solid var(--ion-color-medium);
  border-radius: 8px;
  margin: var(--double-margin) var(--standard-margin);
  /*padding: var(--standard-margin);*/
  -webkit-box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
  -moz-box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
  box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
}
.dashboard-widget .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*width: 100%;*/
  min-height: 2.5rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
  /*padding-top: var(--standard-margin);*/
  padding-left: var(--standard-margin);
  /*padding-bottom: var(--standard-margin);*/
  /*border-bottom: .8px solid #efefef;*/
  background-color: #f6f6f6;
}
.dashboard-widget .card-header {
  /*width: 100%;*/
  padding: 0;
  padding-left: var(--standard-margin);
  background-color: var(--ion-color-welcome-header);
}
.dashboard-widget .header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.dashboard-widget .welcome-header {
  display: flex;
  align-items: center;
  /*width: 100%;*/
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
  padding-top: var(--standard-margin);
  padding-left: var(--standard-margin);
  padding-bottom: var(--standard-margin);
  /*border-bottom: .8px solid #efefef;*/
  background-color: #b2d497;
}
.dashboard-widget .header .text {
  letter-spacing: 2px;
  color: var(--text-color-medium-light);
  text-shadow: 1px 1px 2px #c8c8c8;
  /*text-align: center;*/
}
.dashboard-widget .bottom-buttons {
  /*border: 1px solid yellow;*/
  margin-top: var(--standard-margin);
  margin-bottom: var(--standard-margin);
  text-align: center;
  padding: 3px;
}

/* ------------------------------------------------------------------------------------------------------ */
.dashboard-widget.mimble-wallet .timeline-buttons {
  margin-top: var(--standard-margin);
  text-align: right;
}
.dashboard-widget.mimble-wallet .graph-with-timeline-buttons {
  -webkit-box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
  -moz-box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
  box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
  border: 1px solid var(--border-color-light);
  width: 90%;
  margin: var(--standard-margin);
  text-align: center;
}
.dashboard-widget.mimble-wallet .graph-container {
  position: relative;
  height: 25vh;
  width: 90%;
  padding: var(--standard-margin) 0;
}

/* ------------------------------------------------------------------------------------------------------ */
.dashboard-widget.gift-cards .gift-card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.dashboard-widget.gift-cards .slide {
  margin-bottom: var(--standard-margin);
}
.dashboard-widget.gift-cards .nav-col {
  padding-bottom: 60px;
}
.dashboard-widget.gift-cards .nav-col-left {
  text-align: right;
}
.dashboard-widget.gift-cards .nav-col-right {
  text-align: left;
}
.dashboard-widget.gift-cards .slides-col {
  overflow: hidden;
  max-width: 500px;
}
.dashboard-widget.gift-cards {
}
.dashboard-widget.gift-cards .slide-content {
  margin-top: var(--standard-margin);
  margin-bottom: var(--double-margin);
  width: 50vw;
  max-width: 400px;
}
.dashboard-widget.gift-cards .balance {
  color: var(--text-color-light);
  margin-top: var(--small-margin);
  margin-bottom: var(--standard-margin);
}
/* ------------------------------------------------------------------------------------------------------ */
.dashboard-page .list-content {
  padding-left: var(--ion-safe-area-left);
  padding-right: var(--ion-safe-area-right);
}
.dashboard-widget.chats {
  max-height: 25vh;
}
.dashboard-widget.chats .list {
  overflow: auto;
  margin: var(--standard-margin);
}
.corner {
  position:absolute;
  top: .8em;
  right: .4em;
  height: 35%;
  margin: 0;
  padding: 3px;
}
