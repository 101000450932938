.mimble-team-chat-contact-list .avatar {
  width: 2.8rem;
  height: 2.8rem;
}
.mimble-team-chat-contact-list .contact-name {
  font-size: 1.2rem;
}
.mimble-team-chat-contact-list ion-item {
  --inner-padding-top: 14px;
  --inner-padding-bottom: 14px;
}
