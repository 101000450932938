.merchant-grid .row {
  margin-bottom: var(--standard-margin);
}
.merchant-grid .col {
  --ion-grid-column-padding: var(--standard-margin);
}
.merchant-grid .logo {
  width: 60px;
  margin-bottom: var(--standard-margin);
  position: relative;
}
.merchant-grid .label {
  text-align: center;
  font-size: var(--small-text-size);
  color: var(--text-color-light);
}
.merchant-grid .favorite-organization-icon {
  color: #fdd53e;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
