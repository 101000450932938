.gift-card-barcode {
  position: relative;
}
.gift-card-barcode .zoom-icon {
  height: 24px;
  width:  24px;
  color: var(--ion-color-medium);
}
.gift-card-barcode .zoom-button-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.8);
  border-top-left-radius: 50%;
}
