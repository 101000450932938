.toggle-with-labels {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid var(--border-color-light);
  border-radius: 40px;
}
.toggle-with-labels .selected {
  color: var(--ion-color-primary);
}
.toggle-with-labels .unselected {
  color: var(--ion-color-medium);
}
.toggle-with-labels ion-toggle {
  width: 50px;
  height: 26px;
  --background: var(--ion-color-primary);
  --border-color: var(--ion-color-light);
  padding: 5px;
}
