.avatar-without-badge {
  margin-right: 10px;
}
.avatar-with-badge {
  display: flex;
  flex-direction: row;
  position: relative;
  padding-right: 10px;
}
.avatar-with-badge .badge {
  position: absolute;
  top: 0;
  right: 0;
}
.avatar {
  width: 46px;
  height: 46px;
}
.avatar .large {
  width: 100px;
  height: 100px;
}
