.out-of-stock-warning {
  padding: var(--standard-margin);
  margin-bottom: var(--standard-margin);
  border: 1px solid var(--ion-color-warning);
  border-radius: 5px;
}
.out-of-stock-warning .warn-icon {
  flex-shrink: 0;
  color: var(--ion-color-warning);
  width: 34px;
  height: 34px;
  margin-right: var(--standard-margin);
}
