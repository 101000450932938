.token-attachment {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: fit-content;
  border: 1px solid var(--border-color-light);
  background-color: #ececff;
  padding: var(--small-margin);
  border-radius: 5px;
}
.token-attachment .amount {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}
