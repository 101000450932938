.barcode-page {
}
.barcode-page .page-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.barcode-page .merchant-logo {
  position: absolute;
  left: var(--standard-margin);
  top: var(--standard-margin);
  width: 80px;
}
.barcode-page .img-wrapper {
  z-index: 200;
}
.barcode-page .img-wrapper-rotated {
  transform: rotate(270deg);
  z-index: 200;
}
.barcode-page .number-pane {
  position: absolute;
  right: 0;
  bottom: var(--standard-margin);
  width: 100%;
  padding: var(--standard-margin);
  z-index: 10;
  background: rgba(255, 255, 255, .2);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.barcode-page .code {
  font-size: 1.3rem;
  margin-bottom: var(--standard-margin);
  text-align: center;
}
.barcode-page .card-pin-wrapper {
}
.barcode-page .card-pin-wrapper .label {
  color: var(--ion-color-medium);
}
.barcode-page .card-pin {
  text-align: center;
  font-size: 20px;
  color: black;
  padding: 2px 12px;
  border: 1px solid var(--ion-color-medium);
  border-radius: 18px;
  max-width: fit-content;
  margin: auto;
  background-color: #f9f9f9;
}
