.gift-card {
  position: relative;
}
.gift-card-full-bleed {
  -webkit-box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
  -moz-box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
  box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.19);
  border-radius: 16px;
  overflow: hidden;
}
.gift-card-full-bleed-small {
  -webkit-box-shadow: 2px 3px 2px -1px rgba(0,0,0,0.19);
  -moz-box-shadow: 2px 3px 2px -1px rgba(0,0,0,0.19);
  box-shadow: 2px 3px 2px -1px rgba(0,0,0,0.19);
  border-radius: 8px;
}
.gift-card .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gift-card .overlay .form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  /*border: 1px solid yellowgreen;*/
}
.gift-card .overlay .activate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40%;
  background-color: rgba(255, 255, 255, 0.4);
  /* border-radius: 8px; */
}
.gift-card .overlay .form .header-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /*align-items: center;*/
  /*padding-left: var(--standard-margin);*/
  /*border: 1px solid green;*/
  /*font-size: var(--large-text-size);*/
  /*color: white;*/
  /*color: var(--text-color-light);*/
  /*text-shadow: 1px 1px 2px #c8c8c8;*/
}
.gift-card .overlay .form .button-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gift-card .overlay .form .buttons {
  /* border: 1px solid red; */
  /*padding: var(--standard-margin);*/
}
.gift-card .overlay .form .circle-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  height: 74px;
  width: 74px;
  border-radius: 50%;

}
.gift-card .overlay .form .action-button {
  height: auto;
  /* border: 1px solid red; */
  /*padding: var(--standard-margin);*/
}
.gift-card .overlay .form .button-icon {
  width: 28px;
  height: 28px;
  color: var(--ion-color-medium-shade);
}
.gift-card .overlay .form .button-label {
  margin-top: 2px;
  font-size: 0.5rem;
  color: var(--ion-color-medium-shade);
}
.gift-card .overlay .form .action-button-inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* border: 1px solid red; */
  /*padding: var(--standard-margin);*/
}
.gift-card .overlay .form .buttons .grid {
  --ion-grid-padding: 0;
}
.gift-card .overlay .form .buttons .grid .col {
  --ion-grid-column-padding: 2px;
  display: flex;
  justify-content: center;
}

/* see: https://www.goldennumber.net/credit-cards/,
 CREDIT_CARD_ASPECT_RATIO: 1.592356687898089,
*/
.gift-card-with-merchant-logo {
  height: 0;
  overflow: hidden;
  padding-top: 62.8%;
  position: relative;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  -webkit-box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.14);
  -moz-box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.14);
  box-shadow: 4px 6px 5px -1px rgba(0,0,0,0.14);
  background-color: white;
}
.gift-card-with-merchant-logo-small {
  border-radius: 8px;
  -webkit-box-shadow: 2px 3px 2px -1px rgba(0,0,0,0.14);
  -moz-box-shadow: 2px 3px 2px -1px rgba(0,0,0,0.14);
  box-shadow: 2px 3px 2px -1px rgba(0,0,0,0.14);
}
