.chat-attachment-preview {
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
}

.chat-attachment-preview .delete-icon {
  position: absolute;
  z-index: 100;
  top: -6px;
  right: -6px;
  color: var(--ion-color-medium);
  background-color: var(--ion-color-light);
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
