.member-message-templates-page .memberMessageTemplateListHeader {
  display: flex;
}
.member-message-templates-page .memberMessageTemplateListHeaderCol1 {
  display: flex;
  justify-content: flex-end;
  /*border: 1px solid darkseagreen;*/
}
.member-message-templates-page .inlineCheckboxWithLabel {
  display: flex;
  align-items: center;
  margin-left: var(--standard-margin);
}
.member-message-templates-page .actions-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: var(--small-margin);
}
