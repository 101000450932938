.payment-method-item  {
  flex: 1;
  border: 1px solid lightgray;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80px;
  cursor: pointer;
  overflow: hidden;
  padding: 4px 2px;
  margin: 0 2px;
}
.payment-method-item-disabled {
  background-color: #efefef;
}
.payment-method-item-selected {
  border: 1px solid #4a6fc6;
  background-color: #bed1ff;
}
.payment-method-item .image {
  width: 30px;
}
.payment-method-item .icon {
  width: 30px;
  height: 30px;
}
.payment-method-item .label {
  margin-top: 6px;
  font-size: var(--small-text-size);
  text-align: center;
}
.payment-method-item .label-disabled {
  margin-top: 6px;
  font-size: var(--small-text-size);
  text-align: center;
  color: var(--text-color-light);
}
.payment-method-item .token-image {
  width: 24px;
  height: 24px;
}
