.log-history {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.log-history .data-grid {
  flex: 1;
}
.log-history .detail-section {
  padding: var(--standard-margin);
  margin-top: var(--double-margin);
  border: 1px solid var(--border-color-light);
}
.log-history .detail-section .data-display {
  overflow: auto;
  max-height: 150px;
}

