.chat-attachment-preview-section {
  display: flex;
  justify-content: flex-end;
  padding: var(--standard-margin);
}
.chat-attachment-preview-section .animation {
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  height: 100px;
  width: 100px;
}
.chat-attachment-preview-section .attachment-image {
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  object-fit: cover;
  max-height: 100px;
  max-width: 200px;
}
.image-preview-modal {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  justify-content: center;
  padding: var(--standard-margin);
  height: 100%;
  width: 100%;
  cursor: pointer;
  --background: transparent;
}
.image-preview-modal .modal-wrapper {
  height: 100%;
  width: 100%;
}
.image-preview-modal .modal-wrapper .ion-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image-preview-modal .image-preview-wrapper {
  max-width: 70vw;
  max-height: 70vh;
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 8px;
  overflow: hidden;
}
