.animations {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  padding: var(--small-margin);
}
.animations .lottie {
  width: 60px;
  height: 60px;
  border: 1px solid var(--border-color-light);
  border-radius: 4px;
}
.animations .lottie.selected {
  border: 2px solid var(--ion-color-primary);
}
.animations .button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
