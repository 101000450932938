.choicesControl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--standard-margin);
  width: 100%;
  /*border: 1px solid brown;*/
}
.choicesItem {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: var(--standard-margin);
  text-align: center;
  margin-bottom: var(--standard-margin);
  width: 100%;
  cursor: pointer;
}
.choicesItemDisabled {
  background-color: #efefef;
}
.choicesItemSelected {
  border: 3px solid #4a6fc6;
  background-color: #bed1ff;
}
.choicesItemLine1 {
  font-size: var(--large-text-size);
}
.choicesItemLine1Xl {
  font-size: 3rem;
}
.choicesItemLine1Disabled {
  color: var(--text-color-light);
}
.choicesItemLine2 {
  font-size: var(--small-text-size);
  margin-top: 6px;
}
.choicesItemLine2Disabled {
  color: var(--text-color-light);
}
