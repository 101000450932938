.member-message-template-page .caption {
  font-size: x-large;
  margin: 0 var(--standard-margin);
}
.member-message-template-page .grid-wrapper {
  flex-grow: 1;
  position: relative;
  height: 30vh;
}
.member-message-template-page .actions-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: var(--small-margin);
}
