.contact-header {
  position: relative;
}
.contact-header .row0 {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;
}
.contact-header .center-block {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-left: var(--standard-margin);
}
.contact-header .avatar {
  width: 46px;
  height: 46px;
}
.contact-header .contact-name {
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  max-width: 60vw;
  text-overflow: ellipsis;
}
.contact-header .row1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contact-header .new-gift-button {
}
