
/* ----------------------- Gift Cards ------------------------ */
.wallet-page .purchases-wallet-header {
  padding-top: var(--standard-margin);
  padding-right: calc(var(--standard-margin) + var(--ion-safe-area-right));
  padding-left: calc(var(--standard-margin) + var(--ion-safe-area-left));
  padding-bottom: var(--standard-margin);
  border-bottom: 1px solid var(--ion-color-light);
}
.wallet-page .purchases-wallet-header .row0 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.wallet-page .purchases-wallet-header .row0 .button {
  width: 4rem;
  height: 2.6rem;
  margin: 0 var(--standard-margin);
  margin-right: 0;
  --padding-start: 0;
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
}
.wallet-page .purchases-wallet-header .row0 .button .icon {
  width: 2rem;
  height: 2rem;
}
.wallet-page .purchases-wallet-header .row1 {
  display: flex;
  margin-left: var(--standard-margin);
  margin-right: var(--standard-margin);
  margin-bottom: var(--double-margin);
  justify-content: center;
}
.wallet-page .section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 2.3rem;
  cursor: pointer;
  padding-left: var(--standard-margin);
  background-color: #f6f6f6;
}
.wallet-page .section-title .text {
  letter-spacing: 2px;
  color: var(--text-color-medium-light);
  text-shadow: 1px 1px 2px #c8c8c8;
}
.wallet-page .button-row {
  display: flex;
  font-size: var(--small-text-size);
  flex-direction: row;
  align-items: center;
  margin: var(--standard-margin);
}
.wallet-page .button-row .button-row-caption {
  font-size: 1rem;
  color: var(--ion-color-medium);
  margin-bottom: var(--small-margin);
}
