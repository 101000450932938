.favorite-merchants-section {
  display: flex;
  flex-direction: column;
}
.favorite-merchants-section .favorite-merchants-row {
  display: flex;
  flex-wrap: wrap;
  padding: var(--standard-margin) 0;
}
.favorite-merchants-section .favorite-merchant-item {
  display: flex;
  flex-direction: column;
  padding: var(--standard-margin);
  margin-bottom: var(--standard-margin);
  position: relative;
}
.favorite-merchants-section .delete-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 100;
}
.favorite-merchants-section .logo {
  width: 80px;
}
.favorite-merchants-section .label {
  text-align: center;
  font-size: var(--small-text-size);
  color: var(--text-color-light);
}

/* ============================ FavoriteMerchantsForm Form ================================ */
.favorite-merchants-form .grid-wrapper {
  overflow: auto;
  height: 55vh;
}
.favorite-merchants-form .grid-wrapper .spinner {
  display: flex;
  margin: var(--double-margin) auto;
}
