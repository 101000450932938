.credit-card-form {
  margin-top: var(--double-margin);
}
.credit-card-form .city-input {
  flex: 5;
  margin-right: var(--standard-margin);
}
.credit-card-form .region-code-input {
  flex: 1;
  margin-right: var(--standard-margin);
}
.credit-card-form .postal-code-input {
  flex: 2;
}
.credit-card-form .cc-number-input {
}
.credit-card-form .cc-exp-input {
  flex: 1;
  margin-right: var(--standard-margin);
}
.credit-card-form .cc-cvc-input {
  flex: 1;
}
.credit-card-form .stripe-input-wrapper {
  padding: 8px 0;
}
.credit-card-form .payment-error-message {
  background-color: var(--ion-color-danger);
  color: #ffffff;
  padding: var(--standard-margin);
  margin-bottom: var(--standard-margin);
}
