.purchase-transfer-info-pane {
  margin-top: var(--double-margin);
}
.purchase-transfer-info-pane .section {
  margin-bottom: var(--double-margin);
}
.purchase-transfer-info-pane .section .section-icon {
  width: 22px;
  height: 22px;
  margin-right: 6px;
  color: var(--ion-color-medium);
}
.purchase-transfer-info-pane .section .section-content {
  margin-left: 28px;
  margin-bottom: var(--double-margin);
}
.purchase-transfer-info-pane .section-caption {
  color: var(--ion-color-medium);
}
.purchase-transfer-info-pane .section-text {
  margin-left: 28px;
  margin-bottom: var(--standard-margin);
}
.purchase-transfer-info-pane .message-text-section {
  display: flex;
  justify-content: flex-end;
  margin-right: var(--standard-margin);
}
