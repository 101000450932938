.gift-message-preview {
  display: flex;
  flex-direction: column;
  color: var(--ion-color-text-message-contrast);
  background-color: var(--ion-color-text-message);
  font-style: italic;
  border: 1px solid var(--border-color-light);
  border-radius: 12px;
  border-bottom-left-radius: 0;
  max-width: 400px;
  object-fit: cover;
}
.gift-message-preview .image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 200px;
  overflow: hidden;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.gift-message-preview .message-wrapper {
  margin-bottom: var(--standard-margin);
  border-top-right-radius: 8px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
}
.gift-message-preview .message-wrapper .attachment-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--small-margin);
  margin-bottom: var(--small-margin);
}
.gift-message-preview .attachment-wrapper .attached-image {
  border-radius: 8px;
  border-top-left-radius: 0;
  overflow: hidden;
  max-width: 300px;
}
