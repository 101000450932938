.info-pane-entry {
}
.info-pane-entry .info-pane-label {
  color: var(--text-color-light);
  font-size: 0.9rem;
}
.info-pane-entry .info-pane-edit-icon {
  color: var(--text-color-light);
  width: 0.8rem;
  height: 0.8rem;
  margin-left: var(--small-margin);
  margin-bottom: 6px;
}
.info-pane-entry .info-pane-value {
  margin-top: 2px;
  letter-spacing: 1px;
  font-weight: bold;
}
