.contact-event-list-item {
  --padding-start: var(--standard-margin);
  --padding-end: 0;
  --inner-padding-end: 0;
}
.contact-event-list-item .item-content {
  flex: 1;
  display: flex;
  overflow: hidden;
  padding: var(--small-margin) 0;
}
.contact-event-list-item .row0-content-col {
  flex-grow: 1;
  min-width: 0;
  padding-left: 5px;
  padding-top: var(--standard-margin);
  cursor: pointer;
}
.contact-event-list-item .control-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.contact-event-list-item .next-button {
  margin-top: auto;
  margin-bottom: auto;
}
.contact-event-list-item .event-countdown {
  text-align: center;
  padding-top: var(--small-margin);
  color:#3b5998;
  font-size: xx-large;
}
.contact-event-list-item .today {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color:#3b5998;
  font-size: large;
  cursor: pointer;
  font-weight: bold;
}
.contact-event-list-item .countdown-text {
  text-align: center;
}
.contact-event-list-item .full-name {
  flex-grow: 1;
  font-size: 1.2rem;
  letter-spacing: 2px;
}

/* ------------------------------------------ Contact List Item Sliding ------------------------------------------- */
.contact-event-list-item-sliding .action-icon {
  margin-bottom: var(--standard-margin);
  width: 26px;
  height: 26px;
}
